import { useState } from "react";
import "./NavBar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "../store/AuthStore";
import logoIcon from "/assets/logo-contapro--regular.png?url";
import logoDark from "/assets/logo-contapro--dark.png?url";
import { eventGa4Service } from "../ga4.service.tsx";
import { CONTAPRO_ANALYTICS } from "./analytics.tsx";
import { menuListItems } from "../dashboard/helpers.ts";
import { useBusinessStore } from "@/store/BusinessStore.tsx";
import whatsapp_green from "/assets/ic_social_whatsapp_green.svg?url";
import whatsapp_dark from "/assets/ic_social_whatsapp_dark.svg?url";

function NavBar({ customCssStyle }: { customCssStyle?: string }) {
  const { user } = useAuthStore((state) => state);
  const { exists_periods_processed } = useBusinessStore((state) => state.business);
  const location = useLocation();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const logout = useAuthStore((state) => state.logout);
  const isDashboardRoute = location.pathname.includes("dashboard");
  const strokeColor = isDashboardRoute ? "#fff" : "#15161C";
  const updateToken = useAuthStore((state) => state.setUserToken);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getFirstLetter = (name: string) => {
    return name.charAt(0);
  };

  const logoutHandler = () => {
    eventGa4Service({
      action: CONTAPRO_ANALYTICS.HOME.MENU_LOGOUT.ACTION,
    });
    updateToken(null);
    logout();
  };

  const handleDashboardItemClick = (item: MenuItem) => {
    if (!exists_periods_processed) return;

    if (item.tagAnalytics !== "") {
      eventGa4Service({
        action: item.tagAnalytics,
      });
    }

    const basePath = "/dashboard";
    const newUrl = item.path.includes(basePath) ? item.path : `${basePath}/${item.path}`;

    navigate(newUrl, {
      state: {
        page: item.path,
        ...location.state,
      },
    });

    setIsDropdownOpen(false);
  };

  return (
    <div className={"px-[22px] py-5 flex items-center justify-between gap-4 " + customCssStyle}>
      <Link to="/inicio" className="flex-none">
        {isDashboardRoute ? (
          <img src={logoDark} alt="Somos Contapro" />
        ) : (
          <img src={logoIcon} alt="Somos Contapro" />
        )}
      </Link>

      <div className="flex gap-3 items-center" id="step-one-mobile">
        {user && (
          <div className="flex items-center gap-3">
            {(() => {
              const whatsappImage = isDashboardRoute ? whatsapp_dark : whatsapp_green;
              const textColor = isDashboardRoute ? '#ffffff' : 'inherit';
              return (
                <div>
                  <a href="https://wa.me/51945384052?text=Necesito%20obtener%20más%20información" target="_blank" rel="noopener noreferrer">
                    <img src={whatsappImage} alt="WhatsApp" className="inline-block" width="35px" height="35px" />
                  </a>
                  <span style={{ color: textColor }} className="ml-2 mr-6 font-semibold text-sm text-white hidden sm:inline">Soporte</span>
                </div>
              );
            })()}
            <div className="w-4 h-4 p-4 rounded-[58px] bg-[#E0E9FF] flex justify-center items-center">
              <p className="font-medium text-sm f-secondary text-[#616582]">
                {getFirstLetter(user.name) + getFirstLetter(user.last_name)}
              </p>
            </div>
            <p
              className={
                "font-semibold text-sm " + (isDashboardRoute ? "text-white" : "text-black")
              }
            >
              {user.name + " " + user.last_name}
            </p>
          </div>
        )}
        <div className="navbar__dropdown--mobile inline-block">
          <div onClick={toggleDropdown} className="cursor-pointer">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8334 7.5L10 12.5L4.16669 7.5"
                stroke={strokeColor}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          {isDropdownOpen && (
            <div className="mx-6 absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-5 px-4 flex flex-col">
                {isDashboardRoute &&
                  menuListItems.map((item) => (
                    <p
                      key={item.path}
                      className={`block lg:hidden ${
                        exists_periods_processed || item.path === "editar-credenciales"
                          ? "cursor-pointer navbar__link"
                          : "text-neutral-light text-[14px] cursor-default"
                      } mb-4 ${location.pathname === item.path ? "active" : ""}`}
                      onClick={() => handleDashboardItemClick(item)}
                    >
                      {item.title}
                    </p>
                  ))}

                <p
                  className={`navbar__link block cursor-pointer ${isDashboardRoute ? "mb-0" : ""}`}
                  onClick={() => logoutHandler()}
                >
                  Cerrar sesión
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NavBar;
