import Modal, { ModalState } from "@/shared/Modal";
import { useDashboardStore } from "@/store/DashboardStore";
import exclamationIcon from "/assets/ic_exclamation_triangle.svg?url";
import { ObservedModalProps } from "../models/observedModal";
import { useAuthStore } from "@/store/AuthStore";

const RejectedOneInvoiceModal = ({
  modalState,
  setModalState,
  closeModalCallback,
  cpes,
}: ObservedModalProps) => {
  const { declineReportObserved } = useDashboardStore((state) => state);
  const { registerEventLog, view } = useAuthStore((state) => state);

  const onClickDeclineReport = async () => {
    try {
      setModalState(ModalState.LOADING);
      const body = [{ code: cpes.code, reason: "" }];

      await declineReportObserved(body);

      registerEventLog("discrimination", `${view}_rechazar_individual`);

      console.log("Correct report success");
      setModalState(ModalState.DONE);
    } catch {
      setModalState(ModalState.ERROR);
      console.error("Correct report error");
    }
  };

  return (
    <Modal
      modalState={modalState}
      typeModal={"declineReport"}
      showAlertIcon={false}
      onClose={closeModalCallback}
    >
      <div className="w-full py-3">
        <div className="text-center">
          <img className="inline-block" src={exclamationIcon} alt="Alerta" />
          <h2 className="text-xl my-2 leading-7 font-medium">
            ¿Quieres rechazar el comprobante seleccionado?
          </h2>
        </div>
        <div>
          <p className="text-sm mb-3 text-center">
            Ya no se contará como deducible y no se sumará para el cálculo de compras del periodo
            seleccionado.
          </p>

          <div className="sm:flex gap-3 items-center justify-center">
            <button
              className="text-xs flex-none rounded-[20px] w-full sm:w-[76px] px-3 py-2 border border-stone-950"
              onClick={() => closeModalCallback()}
            >
              Cancelar
            </button>
            <button
              className="flex-none rounded-[20px] w-full mb-3 sm:mb-0 sm:w-[76px] px-3 py-2 bg-stone-950 text-white"
              onClick={() => onClickDeclineReport()}
            >
              Aprobar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RejectedOneInvoiceModal;
