import { useEffect, useState } from "react";
import Modal, { ModalState } from "../shared/Modal";
import { formatDate, formatNumber } from "./helpers";
import { useDashboardStore } from "../store/DashboardStore.tsx";
import Card from "../shared/CardInfo.tsx";
import "./Retentions.css";
import StatusBadge from "../shared/StatusBadge.tsx";
import PaginationSizeSelect from "@/components/shared/pagination-size-select.tsx";
import usePagination from "@/hooks/use-pagination.ts";
import Pagination from "@/components/ui/pagination/pagination.tsx";
import FilterPeriodSelect from "@/components/shared/period-filter-select.tsx";
import { Report } from "./models/report.model.ts";
import { SumaryRetentions } from "./models/sumary.model.ts";
import { useApplicationStore } from "@/store/ApplicationStore.tsx";
import useLoadImage from "./validated-invoices/hooks/useLoadImage.ts";
import { useAuthStore } from "@/store/AuthStore.tsx";
import BackButtonToBusiness from "@/components/shared/back_to_business_button.tsx";

interface ShowBillReportReportRetentionProps {
  modalState: ModalState;
  closeModalCallback: () => void;
  urlReport: string;
}

interface TableRowProps {
  reportsRetention: ReportRetentions;
  setModalOpenShowItems: (newState: ModalState) => void;
  setReportSelected: (report: ReportRetentions) => void;
}

interface TableColumn {
  label: string;
  className?: string;
  tooltip?: string;
}

interface TableHeaderProps {
  columns: TableColumn[];
}

const TableHeader = ({ columns }: TableHeaderProps) => {
  return (
    <thead className="header-table">
      <tr>
        {columns.map((column, index) => (
          <th
            key={index}
            className={`header-table_th header-table_th-${index + 1} text-left tracking-wider ${
              column.className ?? ""
            }`}
          >
            <p className="f-secondary font-medium text-sm">{column.label}</p>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const ShowItemsModal = ({
  modalState,
  closeModalCallback,
  urlReport,
}: ShowBillReportReportRetentionProps) => {
  const { addLoadingTag, removeLoadingTag } = useApplicationStore();
  const { isImageLoaded } = useLoadImage(urlReport, modalState, addLoadingTag, removeLoadingTag);

  return (
    isImageLoaded && (
      <Modal
        modalState={modalState}
        typeModal="generic"
        modalWidth="w-4/6"
        showAlertIcon={false}
        onClose={closeModalCallback}
      >
        <img src={urlReport} alt="imagen de comprobante" />
      </Modal>
    )
  );
};

const TableRow = ({
  reportsRetention,
  setModalOpenShowItems,
  setReportSelected,
}: TableRowProps) => {
  const { registerEventLog, view } = useAuthStore((state) => state);

  const onClickShowItems = () => {
    setModalOpenShowItems(ModalState.OPEN);
    setReportSelected(reportsRetention);
    registerEventLog("preview", `${view}_ver_comprobante`);
  };

  return (
    <>
      <tr className="bg-white border-b">
        <th scope="row" className="body_row-th f-secondary">
          {reportsRetention.supplier_business_name}
        </th>
        <td className="body_row-th f-secondary">{reportsRetention.ruc_supplier}</td>
        <td className="body_row-th f-secondary">{formatDate(reportsRetention.issue_date)}</td>
        <td className="body_row-th f-secondary">{reportsRetention.document_number}</td>
        <td className="body_row-th f-secondary">
          s/ {formatNumber(reportsRetention.retention_amount)}
        </td>
        <td className="body_row-th f-secondary">
          <StatusBadge
            text={reportsRetention.state}
            color={reportsRetention.state === "Emitido" ? "green" : "orange"}
          />
        </td>
        <td className="body_row-th f-secondary text-center">
          {reportsRetention.link ? (
            <span
              className="item_link font-semibold f-secondary"
              onClick={() => onClickShowItems()}
            >
              Ver comprobante
            </span>
          ) : (
            <span className="text-gray-400 font-semibold f-secondary">Ver comprobante</span>
          )}
        </td>
      </tr>
    </>
  );
};

const TableRetentions = ({ retenciones }: { retenciones: ReportRetentions[] }) => {
  const [isModalOpenShowItems, setModalOpenShowItems] = useState<ModalState>(ModalState.CLOSE);
  const [reportSelected, setReportSelected] = useState<ReportRetentions | null>(null);
  const columnsHeader = [
    { label: "Agente retenedor" },
    { label: "RUC Emisor" },
    { label: "Fecha de emisión" },
    { label: "Serie - Número" },
    { label: "Importe de retención" },
    { label: "Estado" },
    { label: "" },
  ];

  return (
    <div className="my-5">
      <div className="grid grid-cols-table">
        <table className="max-w-full w-full text-sm text-left rtl:text-right text-gray-500">
          <TableHeader columns={columnsHeader} />
          <tbody>
            {retenciones.map((reports: any, i: number) => (
              <TableRow
                key={"report" + i}
                reportsRetention={reports}
                setModalOpenShowItems={setModalOpenShowItems}
                setReportSelected={setReportSelected}
              />
            ))}
          </tbody>
        </table>

        <ShowItemsModal
          modalState={isModalOpenShowItems}
          closeModalCallback={() => setModalOpenShowItems(ModalState.CLOSE)}
          urlReport={reportSelected?.link ?? ""}
        />
      </div>
    </div>
  );
};

const CardsHeader = ({
  reportsRetention,
}: {
  reportsRetention: Report<SumaryRetentions, ReportRetentions>;
}) => {
  const dataCards = [
    {
      ccsClass: "bill-obs__card--1",
      label: "Total de retenciones",
      amount: null,
      total: reportsRetention.summary.total_items,
    },
    {
      ccsClass: "bill-obs__card--2",
      label: "Monto total de retenciones",
      amount: reportsRetention.summary.total_amount_retentions ?? 0,
      total: null,
    },
  ];

  return (
    <div className="grid grid-cols-2 gap-3 md:flex md:justify-between md:gap-3 mt-5 max-w-[737px]">
      {dataCards.map((data, index) => (
        <Card
          key={`card-bill-observed-${index}`}
          className={`${data.ccsClass} h-full flex-[1_1_100%]`}
        >
          <Card.Body>
            <p className="font-semibold text-sm f-secondary mb-2">{data.label}</p>

            {data.amount || data.amount === 0 ? (
              <p className="text-xl f-secondary font-normal f-secondary">
                s/ {formatNumber(data.amount)}
              </p>
            ) : (
              <p className="text-xl f-secondary font-normal f-secondary">{data.total}</p>
            )}
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

function Retentions() {
  const {
    valuesFilterSelected: { tax_period },
    reportsPurchaseRetentions,
    fetchReportRetentions,
  } = useDashboardStore((state) => state);
  const { pageSize, onChangePageSize, currentPage, onChangePage } = usePagination();

  const { registerEventLog, setView } = useAuthStore((state) => state);
  const accountant_profile = useAuthStore((state) => state.user.accountant_profile);
  useEffect(() => {
    setView("reporte_retenciones");
    const timer = setTimeout(() => {
      registerEventLog("view", "reporte_retenciones");
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    fetchReportRetentions(currentPage, pageSize)
      .then(() => console.info("Dashboard reports retentions fetched"))
      .catch((_) => console.error("Dashboard reports retentions couldn't fetch"));
  }, [tax_period, pageSize, currentPage]);

  return (
    <div>
      <BackButtonToBusiness
        accountantProfile={accountant_profile}
        link="/inicio"
      />
      <div className="md:flex items-center gap-3 justify-between mt-4 lg:mt-6">
        <h2 className="bill-obs-detail__title">Retenciones</h2>
        <div className="flex gap-4">
          <FilterPeriodSelect />
          {/* 
					// TODO: Implement download TXT
					<button
						className="btn__download flex-none bg-primary-black rounded-[40px] text-white text-center font-semibold text-sm px-6 py-2"
						onClick={ () => {} }
					>
						Descargar TXT
					</button> */}
        </div>
      </div>

      {reportsPurchaseRetentions && (
        <>
          <CardsHeader reportsRetention={reportsPurchaseRetentions} />

          {reportsPurchaseRetentions?.data?.vouchers?.length > 0 ? (
            <>
              <div className="my-4 flex justify-end">
                <PaginationSizeSelect pageSize={pageSize} onChange={onChangePageSize} />
              </div>
              <TableRetentions retenciones={reportsPurchaseRetentions.data.vouchers} />
              <Pagination
                currentPage={currentPage}
                pageCount={reportsPurchaseRetentions.data.pagination.total_pages}
                onChangePage={onChangePage}
              />
            </>
          ) : (
            <p className="my-5 text-sm font-normal text-gray-500">
              No hay data para el periodo seleccionado
            </p>
          )}
        </>
      )}
    </div>
  );
}

export default Retentions;
