import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import useDownloadStatusMessage from "../hooks/useDownloadStatusMessages";
import { StatusValues } from "../models/status-download";
import Modal, { ModalState } from "@/shared/Modal";
import { useApplicationStore } from "@/store/ApplicationStore";
import useLoadImage from "../hooks/useLoadImage";
import useLoadPdf from "../hooks/useLoadPDF";
import { useEffect, useRef, useState } from "react";
import ZoomIn from "/assets/ic_zoom_in.svg?url";
import ZoomOut from "/assets/ic_zoom_out.svg?url";
import Close from "/assets/ic_close.svg?url";
import Print from "/assets/ic_print.svg?url";


interface DownloadAlertModalProps {
  status: StatusValues;
  open: boolean;
  onClose(): void;
}

interface PreviewFileModalProps {
  fileUrl: string;
  modalState: ModalState;
  closeModalCallback: () => void;
}

export function DownloadAlertModal({ status, open, onClose }: DownloadAlertModalProps) {
  const { title, description, icon } = useDownloadStatusMessage(status);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader className="text-center items-center gap-4">
          {icon && <img src={icon} className="w-16 h-16" />}
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export function PreviewFileModal({
  fileUrl,
  modalState,
  closeModalCallback,
}: PreviewFileModalProps) {
  const { addLoadingTag, removeLoadingTag } = useApplicationStore();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const isImage = (url: string) => /\.jpeg$/i.test(url);
  const isPdf = (url: string) => /\.pdf$/i.test(url);

  const { isImageLoaded } = useLoadImage(fileUrl, modalState, addLoadingTag, removeLoadingTag);
  const { isPdfLoaded, base64Pdf } = useLoadPdf(
    fileUrl,
    modalState,
    addLoadingTag,
    removeLoadingTag,
  );

  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const imageRef = useRef<HTMLImageElement>(null);
  const printRef = useRef<HTMLDivElement>(null);
  const handleImageLoad = () => {
    if (imageRef.current) {
      const { naturalWidth, naturalHeight } = imageRef.current;
      setImageDimensions({ width: naturalWidth, height: naturalHeight });
    }
  };

  useEffect(() => {
    if (isPdf(fileUrl) && base64Pdf) {
      const byteCharacters = atob(base64Pdf);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      setPdfUrl(blobUrl);
    }
  }, [fileUrl, base64Pdf]);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const modalWidth = isFullScreen ? "w-full" : "w-2/4";
  const modalClass = isFullScreen ? "top-0 left-0 mt-0" : "mt-0";

  const imageWidth = imageDimensions.height > 900 ? "size-4/6" : "w-[80vw]";
  let imageContent;

  const handlePrint = () => {
    const imageUrl = fileUrl;
    const printWindow = window.open('', '_blank');
    if (printWindow) {
      printWindow.document.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <title>Imprimir Imagen</title>
            <style>
              body { margin: 0; padding: 0; display: flex; justify-content: center; align-items: center; height: 100vh; }
              img { max-width: 100%; max-height: 100vh; object-fit: contain; }
            </style>
          </head>
          <body>
            <img src="${imageUrl}" alt="Imagen para imprimir" onload="window.print(); setTimeout(() => window.close(), 500);" />
          </body>
        </html>
      `);
      printWindow.document.close();
    }
  };

  if (isImage(fileUrl)) {
    imageContent = isImageLoaded && (
      <img
        ref={imageRef}
        src={fileUrl}
        alt="imagen de comprobante"
        className={`object-contain ${imageWidth}`}
        onLoad={handleImageLoad}
      />
    );
  } else if (isPdf(fileUrl) && pdfUrl) {
    imageContent = isPdfLoaded && (
      <div ref={printRef}>
        <iframe src={`${pdfUrl}#toolbar=0&navpanes=0`} width="100%" height="560px" />
      </div>
    );
  }

  const handleClose = () => {
    setIsFullScreen(false);
    closeModalCallback();
  };

  useEffect(() => {
    if (modalState === ModalState.CLOSE) {
      setIsFullScreen(false);
    }
  }, [modalState]);
  


  return (
    (isImageLoaded || isPdfLoaded) &&
    modalState === ModalState.OPEN && (
      <Modal
        modalState={modalState}
        typeModal="generic"
        modalWidth={modalWidth}
        showAlertIcon={false}
        onClose={handleClose}
        className={`${modalClass} origin-top`}
        showIconClose={false}
      >
        <div className={`flex flex-col  ${isFullScreen ? "h-screen mt-28" : ""}`}>
          <div className="flex justify-between items-center p-2">
            <div className="flex gap-2">
                <button
                  onClick={toggleFullScreen}
                  className="rounded-md text-sm bg-gray-200 p-2 opacity-60 hover:opacity-90 hover:bg-gray-300 transition-opacity duration-200"
                  type="button"
                >
                  {isFullScreen ?
                    <img className="inline-block w-[20px]" src={ZoomOut} alt="reducir imagen" />
                    :
                    <img className="inline-block w-[20px]" src={ZoomIn} alt="aumentar imagen" />}
                </button>
                <button
                  onClick={handlePrint}
                  className="rounded-md text-sm bg-gray-200 p-2 opacity-60 hover:opacity-90 hover:bg-gray-300 transition-opacity duration-200 md:inline-flex hidden items-center justify-center"
                  type="button"
                >
                  <img className="inline-block w-[25px] mr-2 md:mr-0" src={Print} alt="imprimir" />
                  <span className="md:hidden">Imprimir</span>
                </button>
              </div>
              <button
                onClick={handleClose}
              >
                <img src={Close} alt="cerrar" className="w-[20px]"/>
              </button>
              
            </div>
          <div
            className="relative overflow-y-auto overflow-x-auto"
            style={{
              maxHeight: isFullScreen ? "calc(90vh - 60px)" : "75vh",
              height: isImage(fileUrl) ? "auto" : (isFullScreen ? "calc(90vh - 60px)" : "75vh"),
            }}
          >
            {imageContent}
          </div>
        </div>
      </Modal>
    )
  );
}
