import React, { ReactNode, useState } from "react";
import screenshotRUC from "/assets/captura-sunat-ruc.png";
import screenshotUser from "/assets/captura-sunat-user.png";
import screenshotPassword from "/assets/captura-sunat-password.png";
import { CircleHelp } from "lucide-react";

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

import { Input } from "@/components/ui/input";
import { FormItem, FormItemMessage } from "@/components/ui/form-item";
import IconCloseEye from "@/shared/IconCloseEye";
import IconOpenEye from "@/shared/IconOpenEye";

const URL_TYC = "https://www.somoscontapro.com/terminos-y-condiciones";
const URL_POLICY = "https://www.somoscontapro.com/politicas-de-privacidad";

type ORIGIN_CALL_FORM = "contapro" | "siscont" | "edit";

interface FormInputsProps {
  register: any;
  errors: any;
  origin?: ORIGIN_CALL_FORM;
  isNaturalPerson?: boolean;
}

interface TooltipProps {
  children: ReactNode;
  open: boolean;
  onMouseOver(): void;
  onMouseLeave(): void;
}

const SimpleTooltip = ({ children, open, onMouseOver, onMouseLeave }: TooltipProps) => (
  <TooltipProvider>
    <Tooltip open={open}>
      <TooltipTrigger onMouseEnter={onMouseOver} onMouseLeave={onMouseLeave}>
        <CircleHelp className="text-dark-gray" />
      </TooltipTrigger>
      <TooltipContent className="max-w-72" side="right">
        {children}
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

type TooltipInput = "username" | "ruc" | "password";

const FormComponent: React.FC<FormInputsProps> = ({ register, errors, origin = "contapro", isNaturalPerson = false }) => {
  const [openedTooltip, setOpenedTooltip] = useState<TooltipInput | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleInputFocus = (name: TooltipInput) => {
    setOpenedTooltip(name);
  };

  const handleInputBlur = () => {
    setOpenedTooltip(null);
  };

  const passwordVisible = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <FormItem>
        <Input
          id="name-businesses"
          {...register("name")}
          placeholder="Nombre de la empresa"
          variant={errors.name && "error"}
          disabled={["edit", "siscont"].includes(origin)}
        />
        {errors.name && <FormItemMessage error>{errors.name?.message}</FormItemMessage>}
      </FormItem>
      <FormItem>
        <Input
          id="ruc-business"
          maxLength={11}
          {...register("ruc", {
            onBlur: handleInputBlur,
          })}
          placeholder="RUC"
          variant={errors.ruc && "error"}
          onFocus={() => handleInputFocus("ruc")}
          disabled={["edit", "siscont"].includes(origin)}
          icon={
            <SimpleTooltip
              open={openedTooltip === "ruc"}
              onMouseOver={() => handleInputFocus("ruc")}
              onMouseLeave={handleInputBlur}
            >
              <img src={screenshotRUC} className="w-full" />
              <p className="text-xs text-center mt-2">
                RUC de la empresa de tu cliente. Debe tener 11 números.
              </p>
            </SimpleTooltip>
          }
        />
        {errors.ruc && <FormItemMessage error>{errors.ruc?.message}</FormItemMessage>}
      </FormItem>
      {origin === "siscont" && isNaturalPerson && (
        <>
          <FormItem>
            <Input
              id="paternal-surname"
              {...register("paternal_surname")}
              placeholder="Apellido Paterno"
              variant={errors.paternal_surname && "error"}
            />
            {errors.paternal_surname && (
              <FormItemMessage error>{errors.paternal_surname?.message}</FormItemMessage>
            )}
          </FormItem>
          <FormItem>
            <Input
              id="maternal-surname"
              {...register("maternal_surname")}
              placeholder="Apellido Materno"
              variant={errors.maternal_surname && "error"}
            />
            {errors.maternal_surname && (
              <FormItemMessage error>{errors.maternal_surname?.message}</FormItemMessage>
            )}
          </FormItem>
          <FormItem>
            <Input
              id="given-names"
              {...register("given_names")}
              placeholder="Nombres"
              variant={errors.given_names && "error"}
            />
            {errors.given_names && (
              <FormItemMessage error>{errors.given_names?.message}</FormItemMessage>
            )}
          </FormItem>
        </>
      )}
      <FormItem>
        <Input
          id="nombre-sol"
          maxLength={8}
          {...register("nombreSOL", {
            onBlur: handleInputBlur,
          })}
          autoComplete="off"
          placeholder="Usuario Portal SOL"
          variant={errors.nombreSOL && "error"}
          onFocus={() => handleInputFocus("username")}
          icon={
            <SimpleTooltip
              open={openedTooltip === "username"}
              onMouseOver={() => handleInputFocus("username")}
              onMouseLeave={handleInputBlur}
            >
              <img src={screenshotUser} className="w-full" />
              <p className="text-xs text-center mt-2">
                Usuario con el que ingresas al Portal SOL. Máximo 8 caracteres.
              </p>
            </SimpleTooltip>
          }
        />
        {errors.nombreSOL && <FormItemMessage error>{errors.nombreSOL?.message}</FormItemMessage>}
      </FormItem>
      <FormItem>
        <Input
          id="llave-sol"
          maxLength={12}
          type={!showPassword ? "password" : "text"}
          placeholder="Clave SOL"
          variant={errors.llaveSOL && "error"}
          onFocus={() => handleInputFocus("password")}
          {...register("llaveSOL", {
            onBlur: handleInputBlur,
          })}
          autoComplete="new-password"
          icon={
            <div className="flex items-center">
              <div
                onClick={passwordVisible}
                className="flex mx-2 focus:outline-none"
                aria-label={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
              >
                {!showPassword ? (
                  <IconCloseEye onClick={() => setShowPassword(false)} />
                ) : (
                  <IconOpenEye onClick={() => setShowPassword(true)} />
                )}
              </div>
              <SimpleTooltip
                open={openedTooltip === "password"}
                onMouseOver={() => handleInputFocus("password")}
                onMouseLeave={handleInputBlur}
              >
                <img src={screenshotPassword} className="w-full" />
                <p className="text-xs text-center mt-2">
                  Contraseña con la que ingresas al Portal SOL.
                </p>
              </SimpleTooltip>
            </div>
          }
        />
        {errors.llaveSOL && <FormItemMessage error>{errors.llaveSOL.message}</FormItemMessage>}
      </FormItem>
      {["siscont", "edit"].includes(origin) && (
        <>
          <FormItem>
            <Input
              id="sunat-api-key"
              {...register("sunat_api_key")}
              autoComplete="off"
              maxLength={100}
              placeholder="Sunat API Key (opcional)"
              variant={errors.sunatapikey && "error"}
            />
            {errors.sunat_api_key && (
              <FormItemMessage error>{errors.sunat_api_key?.message}</FormItemMessage>
            )}
          </FormItem>
          <FormItem>
            <Input
              id="sunat-api-secret"
              {...register("sunat_api_secret")}
              autoComplete="off"
              maxLength={100}
              placeholder="Sunat API Secret (opcional)"
              variant={errors.sunatapisecret && "error"}
            />
            {errors.sunat_api_secret && (
              <FormItemMessage error>{errors.sunat_api_secret?.message}</FormItemMessage>
            )}
          </FormItem>
        </>
      )}
      {["contapro", "siscont"].includes(origin) && (
        <div className="flex align-middle mt-5">
          <input type="checkbox" id="terms" {...register("terms")} />
          <label className="w-full pl-1 cursor-pointer" htmlFor="terms">
            Acepto los{" "}
            <a href={URL_TYC} target="_blank" className="link">
              términos y condiciones
            </a>
          </label>
        </div>
      )}
      {["contapro", "siscont"].includes(origin) && (
        <div className="flex align-middle mt-2">
          <input type="checkbox" id="privacy" {...register("privacy")} />
          <label className="w-full pl-1 cursor-pointer" htmlFor="privacy">
            Acepto las{" "}
            <a href={URL_POLICY} target="_blank" className="link">
              políticas de privacidad
            </a>
          </label>
        </div>
      )}
    </>
  );
};

export default FormComponent;
