import { CircleCheck, CircleX, Clock } from "lucide-react";

const statusContapro: { [key: string]: string } = {
  "en proceso": "En proceso",
  "gasto deducible": "Validado",
  "gasto no deducible": "Rechazado",
  "gasto observado": "Observado",
};

const DiscriminationRowCell = ({ status }: { status: string }) => {
  const matchStatusContapro = statusContapro[status] || "En proceso";

  if (matchStatusContapro === "Validado") {
    return (
      <div className="text-center text-success-darkest">
        {matchStatusContapro} <CircleCheck className="h-4 w-4 ml-2 inline-block" />
      </div>
    );
  }

  if (matchStatusContapro === "Observado") {
    return (
      <div className="text-center text-danger-darkest">
        {matchStatusContapro} <CircleX className="h-4 w-4 ml-2 inline-block" />
      </div>
    );
  }

  if (matchStatusContapro === "Rechazado") {
    return (
      <div className="text-center text-danger-darkest">
        {matchStatusContapro} <CircleX className="h-4 w-4 ml-2 inline-block" />
      </div>
    );
  }

  return (
    <div className="text-center text-neutral-normal">
      {matchStatusContapro} <Clock className="h-4 w-4 ml-2 inline-block" />
    </div>
  );
};

export default DiscriminationRowCell;
