import { Checkbox } from "@/components/ui/checkbox";
import { TableColumn } from "@/components/ui/full-table";
import { formatDate, formatNumber } from "@/dashboard/helpers";
import { useCallback, useMemo, useState } from "react";
import { DescargarFactura, VerFactura } from "@/dashboard/validated-invoices/components/factura";
import ActionsPendingRowCell from "../components/ActionsPendingRowCell";
import DiscriminationRowCell from "../components/DiscriminationRowCell";
import { ReportAllInvoices } from "../models/all-invoices-report.model";

export default function useTableColumns(
  tableRows: ReportAllInvoices[],
  currentPage: number,
  pageSize: number,
  statusSelected: string,
) {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const handleCheckboxChange = useCallback(
    (code: string) => {
      if (selectedRows.includes(code)) {
        setSelectedRows(selectedRows.filter((row) => row !== code));
      } else {
        setSelectedRows([...selectedRows, code]);
      }
    },
    [selectedRows],
  );

  const handleSelectAllChange = useCallback(
    (selectAll: boolean) => {
      if (!selectAll) {
        setSelectedRows([]);
      } else {
        setSelectedRows(tableRows.map((row) => row.code));
      }
    },
    [selectedRows, tableRows],
  );

  const columns: TableColumn[] = useMemo(() => {
    return [
      {
        label: () => {
          return (
            <Checkbox
              checked={selectedRows.length === tableRows.length && tableRows.length > 0}
              onCheckedChange={handleSelectAllChange}
            />
          );
        },
        key: "select",
        minWidth: "50px",
        render: (row) => (
          <div className="px-1">
            <Checkbox
              checked={selectedRows.includes(row.code)}
              onCheckedChange={() => handleCheckboxChange(row.code)}
            />
          </div>
        ),
      },
      {
        label: "Fecha de emisión",
        width: "32%",
        key: "issue_date",
        render: ({ issue_date }) => `${formatDate(issue_date)}`,
      },
      {
        label: "Tipo comp.",
        width: "15%",
        key: "type_code",
        render: ({ type_code }) => <p className="text-center"> {type_code} </p>,
      },
      {
        label: "Serie - Número",
        minWidth: "120px",
        key: "serie",
        render: ({ serie, number }) => `${serie} - ${number}`,
      },
      {
        label: "Nombre/Razón social del proveedor",
        minWidth: "250px",
        key: "supplier_business_name",
      },
      {
        label: "Total IGV",
        minWidth: "100px",
        key: "igv_amount",
        render: ({ igv_amount }) => `s/ ${formatNumber(igv_amount)}`,
      },
      {
        label: "Importe total",
        minWidth: "100px",
        key: "total_amount",
        render: ({ total_amount }) => `s/ ${formatNumber(total_amount)}`,
      },
      {
        label: "JPEG",
        width: "35%",
        textAlign: "center",
        key: "file",
        render: ({ img_cpe_link }) => <VerFactura url={img_cpe_link} />,
      },
      {
        label: "Discriminación Contapro",
        width: "20%",
        key: "status",
        render: ({ status }) => <DiscriminationRowCell status={status} />,
      },
      {
        label: "Acciones",
        key: "actions",
        width: "34%",
        textAlign: "center",
        render: (cpe) => (
          <ActionsPendingRowCell
            status={statusSelected}
            statusCpe={cpe.status}
            cpe={cpe}
            currentPage={currentPage}
            pageSize={pageSize}
            setSelectedRows={(rows: Cpes[]) => setSelectedRows(rows.map((row) => row.code))}
          />
        ),
      },
      {
        label: "Descargas",
        width: "65%",
        textAlign: "center",
        key: "downloads",
        colSpan: 2,
        render: (row) => (
          <div
            style={{ display: "flex", gap: "8px", justifyContent: "center", paddingLeft: "30px" }}
          >
            <DescargarFactura url={row.img_cpe_link} type="jpeg" />
            <DescargarFactura url={row.xml_cpe_link} type="xml" />
          </div>
        ),
      },
    ];
  }, [selectedRows, tableRows]);

  return {
    columns,
    selectedRows,
    setSelectedRows,
    handleSelectAllChange,
  };
}
