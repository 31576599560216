import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { typeDocuments } from "@/helpers/typeCPES";
import { useAuthStore } from "@/store/AuthStore";
import { MoveRightIcon } from "lucide-react";

interface ViewVoucherModalProps {
  data: any;
}

export function ViewVoucherModal({ data }: ViewVoucherModalProps) {
  const { registerEventLog, view } = useAuthStore((state) => state);
  const voucher_unique_code = data?.voucher_unique_code;
  const [, , type_cpe = "-", num_cpe = "-", num_cpe_corr = "-"] = voucher_unique_code.split("-");
  //6 20610157921 01 E001 60

  const getDocumentTypeName = (type: string | number): string => {
    const description = typeDocuments[type] || "No especificado";
    return truncateText(description, 75);
  };

  function truncateText(text: string, maxLength: number): string {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  }

  const fields = [
    { label: "Número de constancia", value: data?.certificate_number || "-" },
    { label: "Usuario SOL", value: data?.sol_user || "-" },
    { label: "N° de cuenta de detracciones", value: data?.account_number || "-" },
    { label: "Tipo de cuenta", value: data?.account_type_name || "-" }, //blanco
    { label: "RUC del proveedor", value: data?.customer_identity_number },
    { label: "Nombre/ Razón Social del Proveedor", value: data?.supplier_business_name },
    { label: "Tipo de Operación", value: data?.operation_name || "-" },
    {
      label: "Tipo de bien o servicio",
      value: `${data?.good_or_service_code || "-"} ${data?.good_or_service_name || ""}`,
    },
    { label: "Monto del depósito", value: `S/ ${data?.deposit_amount || 0}` },
    { label: "Fecha y hora de pago", value: data?.payment_datetime || "-" },
    { label: "Periodo tributario", value: data?.tax_period_payment || "-" },
    {
      label: "Tipo de comprobante",
      value: `${type_cpe} - ${getDocumentTypeName(type_cpe)}`,
    },
    {
      label: "Número de comprobante",
      value: `${num_cpe || "-"} - ${num_cpe_corr || "-"}`,
    },
    { label: "Número de operación", value: data?.operation_number || "-" },
    { label: "Número de pago de detracciones", value: data?.detraction_payment || "-" }, //blanco
  ];

  const handleOnClickOpenModal = () => {
    registerEventLog("preview", `${view}_ver_constancia`);
  };

  return (
    <Dialog>
      <DialogTrigger className="flex gap-2 items-center mt-2" onClick={handleOnClickOpenModal}>
        <span className="text-blue-normal cursor-pointer font-bold">Ver Comprobante </span>
        <MoveRightIcon className="w-4 h-4" />
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Constancia de depósito</DialogTitle>
        <DialogDescription className="text-center"></DialogDescription>
        <div className="text-left text-sm">
          <table className="w-full border-collapse">
            <tbody>
              {fields.map((field, index) => (
                <tr key={index}>
                  <td className="font-semibold pr-4 py-1 leading-none">{field.label}:</td>
                  <td className="py-1">{field.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </DialogContent>
    </Dialog>
  );
}
