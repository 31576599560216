import { getCurrentYYYYMM } from "@/helpers/dateFormatted";
import useSetBusiness from "@/hooks/use-set-business";
import { LoginStatus, useAuthStore } from "@/store/AuthStore";
import { useDashboardStore } from "@/store/DashboardStore";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function LoginSiscont() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const email = searchParams.get("email") ?? "";
  const password = searchParams.get("password") ?? "";
  const razonSocial = searchParams.get("razon_social") ?? "";
  const ruc = searchParams.get("ruc") ?? "";
  const token = searchParams.get("token") ?? "";
  const [userMessage, setUserMessage] = useState("Validando datos...");
  const {
    validateToken,
    setLoginRequest,
    loginSiscont,
    setDataSiscont,
    validateRuc,
    registerEventLog,
  } = useAuthStore();
  const { fetchFiltersBusiness, fetchFiltersPeriods, setPeriods } = useDashboardStore();
  const { handleRedirectToDashboard } = useSetBusiness();

  useEffect(() => {
    const validateSiscontWithToken = async (token: string) => {
      try {
        if (!ruc || !razonSocial) return;
        await validateToken(token);
        console.log("token validado");
        /**
         * Se va a validar primero el ruc si es que ya existe
         * y si le pertenece a la cuenta en cuestion
         */
        const { business: existsRuc } = await validateRuc(ruc);
        const user = useAuthStore.getState().user;

        if (existsRuc && user.uuid !== existsRuc.accountant_uuid) {
          setUserMessage(
            `El ruc ${ruc} ya se encuentra registrado en otra cuenta como ${existsRuc.name}, contactar con soporte de ContaPro.`,
          );
          return;
        }
        await fetchFiltersBusiness();
        console.log("empresas obtenidas");
        let businesses = useDashboardStore.getState().filters.businesses; // Obtener el estado actualizado directamente

        const business = businesses.find((business) => business.ruc === ruc);
        if (!business) {
          console.error("No existe ruc asociado al contador");
          setDataSiscont({ email, password, razon_social: razonSocial, ruc });
          navigate("/siscont/registrar-empresa");
          return;
        }
        console.log("existe ruc asociado al contador");
        /**
         * Si tiene periodos procesados obtenemos su listado de periodos
         * caso contrario le seteamos un periodo por default que seria
         * el año y mes actual
         */
        if (business.exists_periods_processed) {
          await fetchFiltersPeriods(business.uuid);
        } else {
          setPeriods([getCurrentYYYYMM()]);
        }
        console.log("periodos obtenidos");
        const filters = useDashboardStore.getState().filters; // Obtener el estado actualizado directamente
        handleRedirectToDashboard(business, filters, "/dashboard");
      } catch (error) {
        setUserMessage("Ocurrió un error al validar tus datos, inténtelo de nuevo en unos minutos");
      }
    };

    const validateSiscontWithCredentials = async (email: string, password: string) => {
      if (!ruc || !razonSocial) return;
      setLoginRequest({ email, password });
      await loginSiscont();
      const loginStatus = useAuthStore.getState().loginStatus;
      const accessToken = useAuthStore.getState().accessToken;
      if (loginStatus === LoginStatus.SUCCESS) {
        registerEventLog("login", "siscont");
        await validateSiscontWithToken(accessToken ?? "");
      } else if (loginStatus === LoginStatus.INCORRECT_CREDENTIALS) {
        console.error("Credenciales incorrectas");
        setDataSiscont({ email, password, razon_social: razonSocial, ruc });
        navigate("/siscont/registrar-cuenta");
      } else if (loginStatus === LoginStatus.ERROR) {
        console.error("Error al iniciar sesión");
        setUserMessage("Ocurrió un error al validar tus datos, inténtelo de nuevo en unos minutos");
      }
    };

    if (token) {
      validateSiscontWithToken(token);
    } else if (email && password) {
      validateSiscontWithCredentials(email, password);
    }
  }, []);

  return (
    <main className="min-h-screen flex flex-col w-full login_container">
      <div className="flex sm:my-auto items-start md:items-center justify-center w-full">
        <h1>{userMessage}</h1>
      </div>
    </main>
  );
}

export default LoginSiscont;
