import * as Yup from "yup";
import { useBusinessStore, BusinessCreationStatus } from "../store/BusinessStore";
import ErrorAlert from "../errors/ErrorAlert";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import registerSuccessImg from "/assets/spc_mobile_success_hand_d_l.svg?url";
import { RegisterBusinessForm } from "../business/helper";
import { Button } from "@/components/ui/button.tsx";
import CredicorpFooter from "@/shared/CredicorpFooter.tsx";
import { useAuthStore } from "@/store/AuthStore.tsx";
import Loader from "@/Loader.tsx";
import { useDashboardStore } from "@/store/DashboardStore.tsx";
import useSetBusiness from "@/hooks/use-set-business.ts";
import { getCurrentYYYYMM } from "@/helpers/dateFormatted.ts";
import NavBarSiscont from "@/shared/NavBarSiscont.tsx";
import FormComponent from "@/business/FormComponent";

function AccountantCreatedSuccessfully() {
  const { handleRedirectToDashboard } = useSetBusiness();
  const { ruc } = useAuthStore.getState().dataSiscont;
  const { fetchFiltersBusiness, setPeriods, fetchFiltersPeriods } = useDashboardStore();

  const onClickFinishButton = async () => {
    try {
      await fetchFiltersBusiness();
      let businesses = useDashboardStore.getState().filters.businesses;
      const business = businesses.find((business) => business.ruc === ruc);

      if (!business) return;

      if (business.exists_periods_processed) {
        await fetchFiltersPeriods(business.uuid);
      } else {
        setPeriods([getCurrentYYYYMM()]);
      }
      const filters = useDashboardStore.getState().filters;
      console.log("business", business);
      console.log("filters", filters);
      handleRedirectToDashboard(business, filters, "/dashboard");
    } catch (error) {
      console.error("ocurrio un error al redireccionar", error);
    }
  };

  return (
    <div className="flex min-h-full items-start md:items-center justify-center w-full">
      <div className="text-center">
        <img className="inline-block" src={registerSuccessImg} alt="Imagen registro exitoso" />
        <h2 className="text-[32px] text-neutral-dark	font-bold my-3">
          Tus comprobantes estarán listos en máximo 12 horas
        </h2>

        <p className="text-[16px] text-neutral-dark font-[400] mb-4">
          Te notificaremos al celular que registraste cuando tus comprobantes estén listos
        </p>

        <button
          type="button"
          className="primary-button-2 py-2 px-10 mb-5"
          onClick={onClickFinishButton}
        >
          Entendido
        </button>
      </div>
    </div>
  );
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Ingrese el nombre de la empresa a registrar"),
  ruc: Yup.string()
    .required("Ingrese el RUC")
    .matches(/^\d+$/, "El RUC solo debe contener números")
    .length(11, "El RUC debe de tener 11 dígitos."),
  nombreSOL: Yup.string()
    .length(8, "El usuario SOL debe tener 8 caracteres")
    .matches(/^[A-Z0-9]+$/, "El usuario SOL solo debe contener letras mayúsculas y números")
    .required("Ingrese nombre de usuario"),
  llaveSOL: Yup.string().required("Ingrese su clave SOL"),
  sunat_api_key: Yup.string().optional(),
  sunat_api_secret: Yup.string().optional(),
  paternal_surname: Yup.string().optional(),
  maternal_surname: Yup.string().optional(),
  given_names: Yup.string().optional(),
  terms: Yup.boolean().oneOf([true], ""),
  privacy: Yup.boolean().oneOf([true], ""),
});

function Form() {
  const business = useBusinessStore((state) => state.business);
  const setBusiness = useBusinessStore((state) => state.setBusiness);
  const setProfileSOL = useBusinessStore((state) => state.setProfileSOL);
  const createBusiness = useBusinessStore((state) => state.createBusiness);
  const { registerEventLog } = useAuthStore((state) => state);

  const { razon_social, ruc } = useAuthStore.getState().dataSiscont;
  const IsNaturalPerson = ruc.startsWith("10");

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    clearErrors,
    reset,
  } = useForm<RegisterBusinessForm>({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      ruc,
      name: razon_social,
    },
  });

  function onClickRegisterButton({
    name,
    ruc,
    nombreSOL,
    llaveSOL,
    sunat_api_key,
    sunat_api_secret,
    paternal_surname,
    maternal_surname,
    given_names,
  }: RegisterBusinessForm) {
    setBusiness({
      ...business,
      name,
      ruc,
      paternal_surname,
      maternal_surname,
      given_names,
    });

    setProfileSOL({
      username: nombreSOL,
      passwordSOL: llaveSOL,
      sunat_api_key,
      sunat_api_secret,
    });

    createBusiness()
      .then(() => {
        console.log("Business created");
        registerEventLog("register_business", "siscont_formulario_empresa");
      })
      .catch((_) => {
        console.error("Error creating business...");
        clearErrors();
        reset();
      });
  }

  return (
    <div className="items-center justify-center">
      <form
        onSubmit={handleSubmit(onClickRegisterButton)}
        method="POST"
        className="w-full max-w-lg"
        autoComplete="off"
      >
        <h1 className="text-center text-2xl font-bold mb-2 text-neutral-dark">
          Registra tu empresa
        </h1>
        <p className="text-center text-base mb-4 text-neutral-normal font-[400]">
          Ingresa los datos de tu cliente y visualiza todo el detalle contable.
        </p>
        <FormComponent register={register} errors={errors} origin={"siscont"} isNaturalPerson={IsNaturalPerson} />
        <Button id="btn-register" type="submit" className="w-full mt-4" disabled={!isValid}>
          Registrar
        </Button>
      </form>
    </div>
  );
}

function RegisterBusinessSiscont() {
  const accountantCreatedSuccessFully = useBusinessStore(
    (state) => state.businessCreatedSuccessFully,
  );
  const errorMessages: ErrorMessage[] = useBusinessStore((state) => state.errorMessages);

  return (
    <div className="min-h-screen flex flex-col w-full">
      <Loader />
      <NavBarSiscont />
      {[BusinessCreationStatus.NOT_CREATED, BusinessCreationStatus.ERROR].includes(
        accountantCreatedSuccessFully,
      ) && (
        <div className="flex flex-col min-h-[80vh] items-center w-full">
          <div className="mt-8">
            {accountantCreatedSuccessFully === BusinessCreationStatus.ERROR && (
              <ErrorAlert errorMessages={errorMessages} />
            )}
            <Form />
          </div>
          <CredicorpFooter />
        </div>
      )}

      {accountantCreatedSuccessFully === BusinessCreationStatus.CREATED && (
        <div className="flex min-h-[80vh] items-center justify-center">
          <AccountantCreatedSuccessfully />
        </div>
      )}
    </div>
  );
}

export default RegisterBusinessSiscont;
