declare global {
  interface Window {
    grecaptcha: any;
  }
}

import handleInputChange from "@/helpers/patternsInput.ts";
import { getUTMParams } from "@/hooks/use-utm.ts";
import useRecaptcha from "@/hooks/useRecaptcha.ts";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ErrorAlert from "../errors/ErrorAlert";
import { eventGa4Service } from "../ga4.service.tsx";
import { registerAccountant } from "../services/customer-io.ts";
import { CONTAPRO_ANALYTICS } from "../shared/analytics.tsx";
import { useAuthStore } from "../store/AuthStore";
import CustomInput from "./components/CustomInput";
import { Button } from "@/components/ui/button.tsx";

const URL_TYC = "https://www.somoscontapro.com/terminos-y-condiciones";
const URL_PRIVACY = "https://www.somoscontapro.com/politicas-de-privacidad";

function trimObjectValues(obj: { [key: string]: any }): User {
  const trimmedObject: { [key: string]: any } = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      trimmedObject[key] = typeof obj[key] === "string" ? obj[key].trim() : obj[key];
    }
  }

  return trimmedObject as User;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Ingrese su nombre"),
  last_name: Yup.string().required("Ingrese sus apellidos"),
  document_number: Yup.string()
    .required("Ingrese su documento de identidad")
    .matches(/^\d{8}$/, "Ingrese un DNI válido"),
  email: Yup.string()
    .required("Ingrese su correo electrónico")
    .email("Ingrese un correo electrónico válido"),
  phone_number: Yup.string()
    .required("Ingrese su número de celular")
    .matches(/^[9]\d{8}$/, "Ingrese un número telefónico válido"),
  accountant_profile: Yup.string().required("Seleccione su perfil de contador"),
  password: Yup.string()
    .min(6, "La contraseña debe de tener como mínimo 6 caracteres")
    .matches(/^(?=.*[a-z])(?=.*[A-Z]).*$/, {
      message: "La contraseña debe contener al menos una mayúscula y una minúscula",
      excludeEmptyString: true,
    })
    .required("Ingrese su contraseña"),
  coupon_code: Yup.string().optional(),
  terms: Yup.boolean().oneOf([true], ""),
  privacy: Yup.boolean().oneOf([true], ""),
});

function RegisterAccountantForm() {
  const errorMessages: ErrorMessage[] = useAuthStore((state) => state.errorMessages);
  const navigate = useNavigate();
  const KEY_RECAPTCHA = import.meta.env.VITE_KEY_RECAPTCHA;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<User>({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const setUser = useAuthStore((state) => state.setNewUser);
  const createAccount = useAuthStore((state) => state.createUser);

  const { executeRecaptcha, getRecaptcha } = useRecaptcha(KEY_RECAPTCHA);

  const onSubmit = async (data: User) => {
    const credentials = import.meta.env.VITE_CUSTOMERIO_CREDENTIALS;
    if (isSubmitting) return;

    const cleanData: User = trimObjectValues(data);
    const utmParams = getUTMParams();
    const dataWithUTM = { ...cleanData, ...utmParams };

    if (getRecaptcha) {
      const token = await executeRecaptcha("REGISTER_USER");
      const registerData = {
        ...dataWithUTM,
        coupon_code: data.coupon_code || undefined,
        captcha_token: token || undefined,
        captcha_action: "REGISTER_USER",
      };
      setUser(registerData);
      createAccount()
        .then(async () => {
          eventGa4Service({
            action: CONTAPRO_ANALYTICS.AUTH.REGISTER_USER_PAGE.BUTTON_REGISTER.ACTION,
          });
          if (credentials) {
            await registerAccountant(data);
          }
          localStorage.setItem("isFirstLogin", "true");
          localStorage.setItem("isFirstViewDashboard", "true");
          navigate("/registrar-empresas");
        })
        .catch(() => console.error("Error getting user data..."));
    }
  };

  const handleClickLogin = () => {
    eventGa4Service({
      action: CONTAPRO_ANALYTICS.AUTH.REGISTER_USER_PAGE.BUTTON_LOGIN.ACTION,
    });
  };

  return (
    <div className="max-w-[488px] px-4 mx-auto my-auto lg:p-0">
      <ErrorAlert errorMessages={errorMessages} />

      <form method="POST" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <p className="font-medium text-2xl mb-5 block md:hidden">
          Ahorra <span className="font-bold text-[#1B68FF]">50% de tiempo</span> en la <br />
          declaración de IGV de tus clientes
        </p>

        <h1 className="text-sm font-normal pb-3 mt-0 lg:text-lg lg:font-bold">
          No pierdas tus 7 días de prueba gratis.
        </h1>

        <div className="flex flex-col md:flex-row md:gap-4">
          <CustomInput<User>
            id="name"
            labelInput="Nombres"
            type="text"
            placeholder="Ingrese sus nombres"
            register={register}
            handleChange={handleInputChange("letters")}
            errorMessage={errors.name?.message}
          />

          <CustomInput<User>
            id="last_name"
            labelInput="Apellidos"
            type="text"
            placeholder="Ingrese sus apellidos"
            register={register}
            handleChange={handleInputChange("letters")}
            errorMessage={errors.last_name?.message}
          />
        </div>

        <div className="flex flex-col md:flex-row md:gap-4">
          <CustomInput<User>
            id="document_number"
            labelInput="DNI"
            type="text"
            placeholder="Ej. 70897878"
            maxLength={8}
            register={register}
            handleChange={handleInputChange("numbers")}
            errorMessage={errors.document_number?.message}
          />

          <CustomInput<User>
            id="phone_number"
            labelInput="N° celular"
            type="text"
            placeholder="Ej. 989898978"
            maxLength={9}
            register={register}
            handleChange={handleInputChange("numbers")}
            errorMessage={errors.phone_number?.message}
          />
        </div>

        <CustomInput<User>
          id="email"
          labelInput="Correo electrónico"
          type="text"
          placeholder="Ingrese su correo electrónico"
          register={register}
          errorMessage={errors.email?.message}
        />

        <div className="relative my-4">
          <select
            defaultValue=""
            {...register("accountant_profile")}
            id="profiles"
            className="peer block w-full text-sm text-[#4D5B70] bg-white cursor-pointer border border-gray-400 rounded-md p-2.5 focus:border-gray-600 focus:outline-none"
          >
            <option value="" disabled>
              Tipo de perfil
            </option>
            <option value="Contador Freelance">Contador Freelance</option>
            <option value="Estudio Contable">Estudio Contable</option>
          </select>
          {errors.accountant_profile && (
            <p className="top-full left-0 text-red-500 text-xs mt-1 ml-1">
              {errors.accountant_profile.message}
            </p>
          )}
        </div>

        <CustomInput<User>
          id="password"
          labelInput="Contraseña"
          type="password"
          placeholder="Ingrese su contraseña"
          register={register}
          errorMessage={errors.password?.message}
        />

        <CustomInput<User>
          id="coupon_code"
          labelInput="Cupón"
          type="text"
          placeholder="Ingrese su cupón de descuento"
          register={register}
          errorMessage={errors.coupon_code?.message}
        />

        <div className="space-y-2">
          <label className="flex items-center cursor-pointer">
            <input type="checkbox" id="terms" {...register("terms")} />
            <span className="pl-2 text-xs">
              Acepto los{" "}
              <a href={URL_TYC} target="_blank" className="text-blue-500 underline">
                términos y condiciones
              </a>
            </span>
          </label>
          <label className="flex items-center cursor-pointer">
            <input type="checkbox" id="privacy" {...register("privacy")} />
            <span className="pl-2 text-xs">
              Acepto las{" "}
              <a href={URL_PRIVACY} target="_blank" className="text-blue-500 underline">
                políticas de privacidad
              </a>
            </span>
          </label>
        </div>

        <div className="pt-4">
          <Button
            id="create-account"
            type="submit"
            className="py-2 px-4 w-full md:w-52"
            disabled={!isValid}
          >
            Crear cuenta
          </Button>
        </div>
      </form>

      <Link
        id="backlogin"
        onClick={() => handleClickLogin()}
        to="/login"
        className="font-semibold f-secondary text-sm inline-block mt-5 text-[#1B68FF] text-center"
      >
        ¿Ya tienes cuenta? Inicia sesión
      </Link>
    </div>
  );
}

export default RegisterAccountantForm;
