import Modal, { ModalState } from "@/shared/Modal";
import exclamationIcon from "/assets/ic_exclamation_triangle.svg?url";
import { useDashboardStore } from "@/store/DashboardStore";
import { ObservedRejectedModalMasiveProps } from "../models/observedRejectedModalMasive";
import { useAuthStore } from "@/store/AuthStore";

const RejectedAllSelectInvoiceModal = ({
  modalState,
  setModalState,
  closeModalCallback,
  cpesSelected,
}: ObservedRejectedModalMasiveProps) => {
  const { declineReportObserved } = useDashboardStore((state) => state);
  const { registerEventLog, view } = useAuthStore((state) => state);

  const onClickAprobeReports = async () => {
    try {
      setModalState(ModalState.LOADING);

      await declineReportObserved(cpesSelected);

      registerEventLog("discrimination", `${view}_rechazar_masivo`);
      setModalState(ModalState.DONE);
    } catch {
      setModalState(ModalState.ERROR);
      console.error("Correct report error");
    }
  };

  return (
    <Modal
      modalState={modalState}
      typeModal="aprobeMasiveReport"
      showAlertIcon={false}
      showIconClose={false}
      className="w-[90%] md:w-[60%] max-w-[576px]"
      onClose={closeModalCallback}
    >
      <div className="w-full py-3">
        <div className="text-center">
          <img className="inline-block" src={exclamationIcon} alt="Alerta" />
          <h2 className="text-xl text-center my-2 font-bold">
            ¿Quieres rechazar los comprobantes seleccionados?
          </h2>
        </div>
        <div className="">
          <p className="text-sm text-center mb-3">
            Ya no se contarán como deducibles, y no se sumarán para el cálculo de compras del
            periodo seleccionado.
          </p>
          <div className="mt-6 sm:flex gap-3 items-center justify-center">
            <div className="order-2">
              <button
                className="rounded-[20px] w-full sm:w-fit mb-3 sm:mb-0 px-4 py-2 bg-stone-950 text-white"
                onClick={() => onClickAprobeReports()}
              >
                Rechazar comprobantes
              </button>
            </div>
            <div className="order-1">
              <button
                className="order-1 rounded-[20px] w-full sm:w-fit px-8 py-2 border border-stone-950"
                onClick={() => closeModalCallback()}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RejectedAllSelectInvoiceModal;
