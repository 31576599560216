import CustomInput from "@/auth/components/CustomInput";
import handleInputChange from "@/helpers/patternsInput";
import { useAuthStore } from "@/store/AuthStore";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ErrorAlert from "@/errors/ErrorAlert";
import Loader from "@/Loader";
import { Button } from "@/components/ui/button";
import { ShieldCheckIcon } from "lucide-react";
import LogoCredicorp from "/assets/Credicorp-Logo.png?url";
import RegisterSiscontImg1 from "/assets/register-siscont-img-1.svg?url";
import RegisterSiscontImg2 from "/assets/register-siscont-img-2.svg?url";
import RegisterSiscontImg3 from "/assets/register-siscont-img-3.svg?url";
import RegisterSiscontImg4 from "/assets/register-siscont-img-4.svg?url";
import NavBarSiscont from "@/shared/NavBarSiscont";

const URL_TYC = "https://www.somoscontapro.com/terminos-y-condiciones";
const URL_PRIVACY = "https://www.somoscontapro.com/politicas-de-privacidad";

const validationSchema = Yup.object().shape({
  document_number: Yup.string()
    .required("Ingrese su documento de identidad")
    .matches(/^\d{8}$/, "Ingrese un DNI válido"),
  email: Yup.string()
    .required("Ingrese su correo electrónico")
    .email("Ingrese un correo electrónico válido"),
  phone_number: Yup.string()
    .required("Ingrese su número de celular")
    .matches(/^[9]\d{8}$/, "Ingrese un número telefónico válido"),
  terms: Yup.boolean().oneOf([true], ""),
  privacy: Yup.boolean().oneOf([true], ""),
});

function RegisterAccountSiscont() {
  const navigate = useNavigate();
  const dataSiscont = useAuthStore((state) => state.dataSiscont);
  const errorMessages: ErrorMessage[] = useAuthStore((state) => state.errorMessages);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<UserSisCont>({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      email: dataSiscont.email,
    },
  });
  const createAccount = useAuthStore((state) => state.createUserSiscont);

  const onSubmit = async (data: UserSisCont) => {
    try {
      if (isSubmitting) return;

      const user: UserRegisterSiscont = {
        name: "SISCONT",
        last_name: "SISCONT",
        document_number: data.document_number,
        email: dataSiscont.email,
        phone_number: data.phone_number,
        password: dataSiscont.password,
      };

      await createAccount(user);

      navigate("/siscont/registrar-empresa");
    } catch (error) {
      console.error("hubo un error al registrar usuario", error);
    }
  };

  return (
    <main className="min-h-screen flex flex-col w-full">
      <Loader />
      <NavBarSiscont />
      <div className="my-4 text-center w-[90%] md:w-[60%] lg:w-[40%] mx-auto">
        <h1 className="text-neutral-dark font-bold text-2xl text-center">
          Obtén todos tus comprobantes con detalle e imagen con Contapro
        </h1>
        <p className="text-neutral-normal font-[500] text-center text-sm mt-4">
          Contapro es el asistente contable virtual respaldado por Credicorp
        </p>
      </div>
      <div className="flex justify-between">
        <div className="basis-[20%] hidden md:block">
          <div>
            <img src={RegisterSiscontImg1} alt="Register Siscont" height={150} width={150} />
          </div>
          <div>
            <img src={RegisterSiscontImg2} alt="Register Siscont" height={400} width={400} />
          </div>
        </div>
        <form
          className="basis-[90%] mx-auto md:basis-[40%] lg:basis-[35%] 2xl:basis-[25%]"
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <ErrorAlert errorMessages={errorMessages} />

          <div className="mb-6">
            <CustomInput<UserSisCont>
              id="document_number"
              labelInput="DNI"
              type="text"
              placeholder="Ej. 70897878"
              maxLength={8}
              register={register}
              handleChange={handleInputChange("numbers")}
              errorMessage={errors.document_number?.message}
            />
          </div>

          <div className="mb-6">
            <CustomInput<UserSisCont>
              id="phone_number"
              labelInput="Celular"
              type="text"
              placeholder="Ej. 989898978"
              maxLength={9}
              register={register}
              handleChange={handleInputChange("numbers")}
              errorMessage={errors.phone_number?.message}
            />
          </div>

          <div className="mb-5">
            <CustomInput<UserSisCont>
              id="email"
              labelInput="Correo electrónico"
              type="text"
              placeholder="Ingrese su correo electrónico"
              register={register}
              disabled={true}
              errorMessage={errors.email?.message}
            />
          </div>

          <div className="flex align-middle">
            <input type="checkbox" id="terms" {...register("terms")} />
            <label className="w-full pl-1 cursor-pointer" htmlFor="terms">
              Acepto los{" "}
              <a href={URL_TYC} target="_blank" className="link">
                términos y condiciones
              </a>
            </label>
          </div>

          <div className="flex align-middle mt-2 mb-4">
            <input type="checkbox" id="privacy" {...register("privacy")} />
            <label className="w-full pl-1 cursor-pointer" htmlFor="privacy">
              Acepto las{" "}
              <a href={URL_PRIVACY} target="_blank" className="link">
                políticas de privacidad
              </a>
            </label>
          </div>

          <div className="pt-4">
            <Button
              id="create-account"
              type="submit"
              className="py-2 px-4 w-full"
              disabled={!isValid}
            >
              Registrarme
            </Button>
          </div>

          <p className="text-center flex flex-col md:flex-row items-center justify-center mx-auto text-gray-600 gap-1 my-6">
            <span className="flex gap-2">
              <ShieldCheckIcon />
              Con respaldo
            </span>
            <img src={LogoCredicorp} alt="Credicorp" className="max-w-32 object-contain" />
          </p>
        </form>
        <div className="basis-[20%] hidden md:block">
          <div className="w-full flex">
            <img
              src={RegisterSiscontImg3}
              alt="Register Siscont"
              className="ml-auto"
              height={400}
              width={400}
            />
          </div>
          <div className="w-full flex">
            <img
              src={RegisterSiscontImg4}
              alt="Register Siscont"
              className="ml-auto"
              height={200}
              width={200}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default RegisterAccountSiscont;
