import { Outlet, Route, Routes } from "react-router-dom";
import Loader from "./Loader.tsx";
import BusinessDashboardApp from "./dashboard/BusinessDashboard.tsx";
import BillsObservedDetails from "./dashboard/observeds/BillsObservedDetails.tsx";
import { useAuthStore } from "./store/AuthStore.tsx";
import ModalTokenExpired from "./dashboard/ModalTokenExpired.tsx";
import NavBar from "./shared/NavBar.tsx";
import "./HomeDashboard.css";
import SideBarDashboard from "./shared/SidebarDashboard.tsx";
import Perceptions from "./dashboard/perceptions/Perceptions.tsx";
import Retentions from "./dashboard/Retentions.tsx";
import ReportPDT from "./dashboard/reportPDT/ReportPDT.tsx";
import Joyride, { Step, CallBackProps } from "react-joyride";
import TourTooltip from "./shared/TourTooltip.tsx";
import useMobileMediaQuery from "./hooks/mediaQuery.ts";
import { useState } from "react";
import Detractions from './dashboard/detractions/Detractions.tsx';
import ValidatedInvoices from "./dashboard/validated-invoices/ValidatedInvoices.tsx";
import EditCredentials from "./dashboard/edit-credentials/Edit-credentials.tsx";
import UploadFilesAT from "./dashboard/subir-archivos/UploadFilesAT.tsx";
import AllInvoices from "./dashboard/all-invoices/AllInvoices.tsx";
import NavBarSiscont from "./shared/NavBarSiscont.tsx";

const isMobile = useMobileMediaQuery();
const steps: Step[] = [
    {
        target: isMobile ? "#step-one-mobile" : "#step-one",
        title: "Encuentra lo que necesitas de forma más fácil",
        content: "Conoce el análisis de tus compras y ventas, comprobantes observados e informe PDT 621.",
        disableBeacon: true,
        placement: isMobile ? 'bottom' : "right",
        ...( isMobile && ({ offset: 30 }))
    },
    {
        target: isMobile ? "#step-two-mobile" : "#step-two",
        title: "Descarga reportes por periodos",
        content: "Visualiza todos los reportes de forma más rápida y en formatos .xls, .XML., RCE y RVIE.",
    },
    {
        target: "#step-three",
        title: "Visualiza análisis de tus comprobantes",
        content: "Analizamos todos los comprobantes y observamos los gastos que puedan ser no deducibles.",
        ...( !isMobile && ({ placement: 'left' }))
    },
    {
        target: "#step-four",
        title: "Visualiza la información de tus otras empresas",
        content: "Regresa al listado, selecciona uno y comienza análisis de tus comprobantes.",
    },
];

const completedOnboardingVideo = localStorage.getItem('completedOnboardingVideo') ?? false;
const completedOnboardingOne = localStorage.getItem("completedOnboardingOne") ?? false;

function HomeDashboard() {
    const tokenExpired = useAuthStore((state) => state.accessTokenExpired);
    const { accountant_profile } = useAuthStore((state) => state.user);
    const [showOnboarding, setShowOnboarding] = useState(completedOnboardingVideo && !completedOnboardingOne)

    const handleOnboardingCallback = ({ status, action }: CallBackProps) => {
        if (status === "finished" || action === 'close') {
            localStorage.setItem("completedOnboardingOne", "true");
        }
    };    

    const handleCloseOnboardingVideo = () => {
        setShowOnboarding(true)
    }

    return (
        <main className="min-h-screen bg-white w-full">
            <Loader />
            {
                accountant_profile === 'Siscont' ? 
                    <NavBarSiscont className="bg-primary-black fixed z-10 md:px-5 py-5 border-none" isDark /> 
                    : 
                    <NavBar customCssStyle={"bg-black bg-[#15161C] navbar_dashboard"} />
            }
            <div className="flex home-dashboard__container min-h-screen">
                <SideBarDashboard />
                {showOnboarding && (
                    <Joyride
                        scrollOffset={150}
                        steps={steps}
                        tooltipComponent={TourTooltip}
                        styles={{
                            options: {
                                arrowColor: "#171717",
                            },
                        }}
                        continuous
                        callback={handleOnboardingCallback}
                    />
                )}
                <div className="ml-0 lg:ml-[233px] mt-[80px] px-6 pt-8 w-full bg-surface-400">
                    <Routes>
                        <Route index element={<BusinessDashboardApp onCloseOnboardingVideo={handleCloseOnboardingVideo} />} />
                        <Route path="comprobantes" element={<AllInvoices />} />
                        <Route path="observaciones" element={<BillsObservedDetails />} />
                        <Route path="percepciones" element={<Perceptions />} />
                        <Route path="retenciones" element={<Retentions />} />
                        <Route path="detracciones" element={<Detractions />} />
                        <Route path="facturas-validadas" element={<ValidatedInvoices />} />
                        <Route path="informe-pdt" element={<ReportPDT />} />
                        <Route path="editar-credenciales" element={<EditCredentials />} />
                        <Route path="subir-archivos" element={<UploadFilesAT />} />
                    </Routes>
                    <Outlet />
                </div>
            </div>

            {tokenExpired && <ModalTokenExpired showModal={true} />}
        </main>
    );
}

export default HomeDashboard;
