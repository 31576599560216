import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import ic_search_mini from '/assets/ic_search_mini.svg?url';
import ic_close_icon_gray from '/assets/ic_close_icon_gray.svg?url';
import ic_angle_left_gray from '/assets/ic_angle_left_gray.svg?url';
import { FilterDropdownProps } from '../models/all-invoices-report.model';
// import { typeDocuments } from '@/helpers/typeDocuments';para agregar tipo de documento según código
import icFilter from "/assets/ic_filter.svg?url";
import icReset from "/assets/ic_reset.svg?url";

type OptionKey = string;

const FILTER_OPTIONS = [{
  key: 'provider_of_vouchers',
  value: 'Proveedores',
},{
  key: 'series_of_vouchers',
  value: 'Serie',
},
 
]


const FilterDropdown: React.FC<FilterDropdownProps> = ({ 
  columns, 
  filters,
  selectedFilter,
  onResetFilter,
  isFilterActive
}) => {
  const [dropdownState, setDropdownState] = useState({
    isOpen: false,
    isSearch: false,
    filter_by: '',
    value_filter: '',
  });
  
  const [searchInputs, setSearchInputs] = useState({
    firstSearch: '',
    optionSearch: ''
  });
  
  const [listFilter, setListFilter] = useState<any[]>([]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const firstSearchInputRef = useRef<HTMLInputElement>(null);
  const optionSearchInputRef = useRef<HTMLInputElement>(null);
 

  useEffect(() => {
    if (dropdownState.isOpen && !dropdownState.filter_by && firstSearchInputRef.current) {
      firstSearchInputRef.current.focus();
    }
  }, [dropdownState.isOpen, dropdownState.filter_by]);

  useEffect(() => {
    if (dropdownState.filter_by && optionSearchInputRef.current) {
      optionSearchInputRef.current.focus();
    }
  }, [dropdownState.filter_by]);

  const toggleDropdown = useCallback(() => {
    setDropdownState(prevState => {
      const newIsOpen = !prevState.isOpen;
      if (!newIsOpen) {
        setSearchInputs({ firstSearch: '', optionSearch: '' });
        return {
          ...prevState,
          isOpen: false,
          isSearch: false,
          filter_by: '',
          value_filter: '',
        };
      }
      return { ...prevState, isOpen: true };
    });
  }, []);

  const outsideClick = useCallback((e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setDropdownState({
        isOpen: false,
        isSearch: false,
        filter_by: '',
        value_filter: '', });
      setSearchInputs({ firstSearch: '', optionSearch: '' });
    }
  }, []);

  const firstSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInputs(prev => ({ ...prev, firstSearch: value }));
    setDropdownState(prev => {
      if(prev.isSearch !== !!value){
        return ({ ...prev, isSearch: !!value })
      }
      return prev;
    });
  }, []);
  
  useEffect(() => {
    if (dropdownState.isOpen && !dropdownState.filter_by && firstSearchInputRef.current) {
      firstSearchInputRef.current.focus();
    }
  }, [dropdownState.isOpen, dropdownState.filter_by, searchInputs.firstSearch]);

  const optionSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInputs(prev => ({ ...prev, optionSearch: e.target.value }));
      
  }, []);

  const searchSubOptions = useCallback(() => {
    const { optionSearch } = searchInputs;
    if (!optionSearch) {
        setListFilter(allSubOptionsRef.current);
    } else {
        const filtro = allSubOptionsRef.current.filter(option =>
            String(option.value).toLowerCase().includes(optionSearch.toLowerCase())
        );
        setListFilter(filtro);
    }
  }, [searchInputs.optionSearch]);

  const clearSearch = useCallback(() => {
    setSearchInputs(prev => ({ ...prev, firstSearch: '' }));
    setDropdownState(prev => ({ ...prev, isSearch: false }));
    setTimeout(() => {
      if (firstSearchInputRef.current) {
        firstSearchInputRef.current.focus();
      }
    }, 0);
  }, []);

  const search = useCallback(() => {
    const filterBy = dropdownState.filter_by || 'series_of_vouchers';
    selectedFilter(filterBy, searchInputs.firstSearch);
    setDropdownState(prev => ({ ...prev, isOpen: false, isSearch: false, filter_by: '', value_filter: '' }));
    setSearchInputs({ firstSearch: '', optionSearch: '' });
    }, [
    searchInputs.firstSearch,
    selectedFilter,
    dropdownState.filter_by
    ]);

  const optionSelect = useCallback((option: OptionKey) => {
    setDropdownState(prev => ({ ...prev, filter_by: option }));
    setSearchInputs(prev => ({ ...prev, optionSearch: '' }));
    if (!option) return;
    let values: any[] = [];
    if (option === "provider_of_vouchers") {
      values = Object.entries(filters?.provider_of_vouchers).map(([key, value]) => ({ key, value }));
    } else if (option === "series_of_vouchers") {
      values = Array.isArray(filters?.series_of_vouchers) ? filters.series_of_vouchers.map(value => ({key: value, value})) : [];
    } 
    const uniqueValues = Array.from(new Set(values));
    allSubOptionsRef.current = uniqueValues;
    setListFilter(uniqueValues);
  }, [columns, filters]);

  const backOptions = useCallback(() => {
    setDropdownState(prev => ({ ...prev, filter_by: '', value_filter: '' }));
    setSearchInputs(prev => ({ ...prev, optionSearch: '' }));
    setListFilter([]);
    setTimeout(() => {
      if (firstSearchInputRef.current) {
        firstSearchInputRef.current.focus();
      }
    }, 0);
  }, []);

  const allSubOptionsRef = useRef<any[]>([]);
  const filteredSubOptions = useMemo(() => {
    return listFilter;
  }, [listFilter]);

  useEffect(() => {
    document.addEventListener('mousedown', outsideClick);
    return () => {
      document.removeEventListener('mousedown', outsideClick);
    };
  }, [outsideClick]);

  const keyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (dropdownState.filter_by) {
        searchSubOptions();
        if (filteredSubOptions.length === 1) {
          selectedFilter(dropdownState.filter_by, filteredSubOptions[0].key);
          setDropdownState(prev => ({ ...prev, isOpen: false, filter_by: '', value_filter: '' }));
          setSearchInputs({ firstSearch: '', optionSearch: '' });
        }
      } else {
        if (!dropdownState.filter_by) {
          search();
        }
      }
    }
  }, [dropdownState.filter_by, searchInputs.optionSearch, search, searchSubOptions, filteredSubOptions, selectedFilter]);

  const firstSearch = () => (
    <>
    <div className="relative mb-3">
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <img src={ic_search_mini} alt="icono de busqueda" />
      </div>
      <input
        ref={firstSearchInputRef}
        type="text"
        placeholder="Buscar factura por serie"
        value={searchInputs.firstSearch}
        onChange={firstSearchChange}
        onKeyDown={keyDown}
        className="bg-gray-100 block w-full pl-10 pr-3 py-2 text-xs text-gray-900 rounded-md focus:outline-none focus:ring-2"
      />

    </div>
    </>
  );

  const activeSearch = () => (
    <div className="flex items-center space-x-2 mb-3">
      <div className="relative flex-grow">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <img src={ic_search_mini} alt="icono de busqueda" />
        </div>
        <input
          ref={firstSearchInputRef}
          type="text"
          value={searchInputs.firstSearch}
          onChange={firstSearchChange}
          onKeyDown={keyDown}
          className="bg-gray-100 block w-full pl-10 pr-3 py-2 text-xs text-gray-900 rounded-md focus:outline-none focus:ring-2"
        />
        <button
          onClick={clearSearch}
          className="absolute inset-y-0 right-0 flex items-center pr-3"
        >
          <img src={ic_close_icon_gray} alt="icono de cerrar" width="17px" />
        </button>
      </div>
      <button
        onClick={search}
        className="bg-gray-700 hover:bg-gray-800 text-white px-4 py-1.5 rounded-md text-xs"
      >
        Buscar
      </button>
    </div>
  );
 
  const renderFilterOptions = () => (
    <>
      {!dropdownState.isSearch && FILTER_OPTIONS.map((option) => (
        <div
          key={option.key}
          className="px-1 py-2 text-xs text-gray-700 hover:bg-gray-100 cursor-pointer"
          onClick={() => optionSelect(option.key)}
        >
          {option.value}
        </div>
      ))}
    </>
  );

  const optionDetail = () => {
    const { filter_by } = dropdownState;
    if (!filter_by) return null;
    const titleSelectedOption = FILTER_OPTIONS.find(option => option.key === filter_by);
    const optionLabel = titleSelectedOption ? titleSelectedOption.value : '';
     
    return (
      <div className="p-3">
        <div className="flex items-center mb-4">
          <button
            onClick={backOptions}
            className="mr-2 p-1 hover:bg-gray-100 rounded-full"
          >
            <img src={ic_angle_left_gray} alt="icono de flecha" />
          </button>
          <span className="font-medium text-xs">{optionLabel}</span>
        </div>
        
        <div className="flex items-center space-x-2 mb-3">
            <div className="relative flex-grow">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <img src={ic_search_mini} alt="icono de busqueda" />
                </div>
                <input
                    ref={optionSearchInputRef}
                    type="text"
                    placeholder={`Buscar ${optionLabel.toLowerCase()}`}
                    value={searchInputs.optionSearch}
                    onChange={optionSearchChange}
                    onKeyDown={keyDown}
                    className="bg-gray-100 block w-full pl-10 pr-3 py-2 text-xs text-gray-900 rounded-md focus:outline-none focus:ring-2"
                />
                {searchInputs.optionSearch && (
                    <button
                        onClick={() => {
                            setSearchInputs(prev => ({ ...prev, optionSearch: '' }));
                            setListFilter(allSubOptionsRef.current);
                        }}
                        className="absolute inset-y-0 right-0 flex items-center pr-3"                >
                        <img src={ic_close_icon_gray} alt="icono de cerrar" width="17px" />
                    </button>
                )}
            </div>
            <button
                onClick={searchSubOptions}
                className="bg-gray-700 hover:bg-gray-800 text-white px-4 py-1.5 rounded-md text-xs"
            >
                Buscar
            </button>
        </div>
        <div className="max-h-48 overflow-y-auto">
            {filteredSubOptions.length > 0 ? (
                filteredSubOptions.map((option) => (
                    <div
                        key={option.key}
                        className="px-1 py-2 text-xs text-gray-700 hover:bg-gray-100 cursor-pointer"
                        onClick={() => selectedFilter(filter_by, option.key)}
                    >
                        {option.value}
                    </div>
                ))
            ) : (
                <div className="px-1 py-2 text-xs text-gray-500">
                    No se encontraron registros.
                </div>
            )}
        </div>
      </div>
    );
  };

  return (
    <div className='flex items-center'>
      <div className="relative" ref={dropdownRef}>
        <button
          type="button"
          onClick={toggleDropdown}
          className="inline-flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 w-full"
        >
          <span className="mr-3 text-[14px] font-semibold">Filtrar</span>
          <img src={icFilter} alt="icono de filtro" />
        </button>

        {dropdownState.isOpen && (
          <div className="absolute right-0 mt-2 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
            {!dropdownState.filter_by ? (
              <div className="p-3">
                {!dropdownState.isSearch ? firstSearch() : activeSearch()}
                {renderFilterOptions()}
              </div>
            ) : (
              optionDetail()
            )}
          </div>
        )}
      
      </div>
     {isFilterActive && (
            <button
              type="button"
              className="mr-3 text-[14px] font-semibold"
              onClick={onResetFilter}
            >
              <img src={icReset} alt='restablecer' width={'20px'} height={'20px'}/>
            </button>
      )}
    </div>
    
  );
};

export default FilterDropdown;