import logoContapro from "/assets/logo-contapro--regular.png?url";
import logoContaproDark from "/assets/logo-contapro--dark.png?url";
import logoSiscont from "/assets/logo-siscont.svg?url";
import logoSiscontDark from "/assets/logo-siscont--dark.svg?url";
import { cn } from "@/lib/utils";
import whatsapp_green from "/assets/ic_social_whatsapp_green.svg?url";
import whatsapp_dark from "/assets/ic_social_whatsapp_dark.svg?url";

const ContaproLogo = ({ isDark }: { isDark?: boolean }) => {
  const logo = isDark ? logoContaproDark : logoContapro;
  return <img src={logo} alt="Contapro" />;
};

const SiscontLogo = ({ isDark }: { isDark?: boolean }) => {
  const logo = isDark ? logoSiscontDark : logoSiscont;
  return <img src={logo} alt="Siscont" height={200} width={200} />;
};

const WhatsAppButton = ({ isDark }: { isDark?: boolean }) => {
  const whatsappImage = isDark ? whatsapp_dark : whatsapp_green;
  const textColor = isDark ? '#ffffff' : 'inherit';
  return (
    <div className="mr-2">
      <a href="https://wa.me/51945384052?text=Necesito%20obtener%20más%20información" target="_blank" rel="noopener noreferrer">
        <img src={whatsappImage} alt="WhatsApp" className="inline-block" width="35px" height="35px" />
      </a>
      <span style={{ color: textColor }} className="ml-2 mr-6 font-semibold text-sm text-white hidden sm:inline">Soporte</span>
    </div>
  );
};

function NavBarSiscont({ className, isDark }: { className?: string; isDark?: boolean }) {
  return (
    <div
      className={cn(
        "mx-auto w-full py-5 px-5 md:px-28 mb-8 border-b flex justify-between items-center border-b-neutral-light",
        className,
      )}
    >
      <ContaproLogo isDark={isDark} />
      <div className="flex items-center">
        <WhatsAppButton isDark={isDark} />
        <SiscontLogo isDark={isDark} />
      </div>
    </div>
  );
}

export default NavBarSiscont;
