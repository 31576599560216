import { useState } from "react";
import { UseFormRegister, FieldValues, Path } from "react-hook-form";
import IconCloseEye from "../../shared/IconCloseEye";
import IconOpenEye from "../../shared/IconOpenEye";
import classNames from "classnames";

interface CustomInputProps<T extends FieldValues> {
  id: keyof T;
  labelInput: string;
  type: string;
  placeholder: string;
  disabled?: boolean;
  register: UseFormRegister<T>;
  errorMessage: string | undefined;
  maxLength?: number;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const getPath = <T,>(fieldName: keyof T): Path<T> => {
  return fieldName as unknown as Path<T>;
};

const CustomInput = <T extends FieldValues>({
  id,
  labelInput,
  type,
  register,
  maxLength,
  errorMessage,
  handleChange,
  disabled = false,
}: CustomInputProps<T>) => {
  const [passwordType, setPasswordType] = useState("password");
  const [isFocused, setIsFocused] = useState(false);
  const handlePatternInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (handleChange) {
      handleChange(e);
    }
  };
  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <div className="flex-[0_1_50%] relative my-2">
      <div className={`input-group-button inline-flex ${errorMessage ? "border-red-500" : ""}`}>
        <input
          id={id.toString()}
          type={id.toString().toLowerCase().includes("password") ? passwordType : type}
          className={classNames(
            "w-full bg-transparent focus:placeholder-gray-200 focus:bg-transparent focus:outline-none peer",
            { "bg-gray-200 text-gray-500 opacity-70 cursor-not-allowed": disabled },
          )}
          autoComplete="off"
          placeholder=""
          maxLength={maxLength}
          disabled={disabled}
          {...register(getPath(id))}
          onFocus={() => handleFocus()}
          onInput={handlePatternInput}
        />

        <label
          htmlFor={id.toString()}
          className={`absolute text-sm text-[#4D5B70] duration-300 transform ${
            isFocused ? "-translate-y-3 scale-75" : "-translate-y-1/2"
          } origin-[0] bg-white px-2 top-[0.20rem] z-10`}
        >
          {labelInput}
        </label>

        {type === "password" && (
          <>
            {passwordType === "password" ? (
              <IconCloseEye onClick={() => setPasswordType("text")} />
            ) : (
              <IconOpenEye onClick={() => setPasswordType("password")} />
            )}
          </>
        )}
      </div>

      {errorMessage && (
        <span className="top-full left-0 text-red-500 text-xs mt-1 ml-1">{errorMessage}</span>
      )}
    </div>
  );
};

export default CustomInput;
