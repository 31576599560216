import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { BusinessDashboardGuard, PrivateRoutes, PublicRoutes } from "./Guard.tsx";
import Login from "./auth/Login.tsx";
import RegisterAccountant from "./auth/RegisterAccountant.tsx";
import Home from "./Home.tsx";
import ErrorPage from "./errors/ErrorPage.tsx";
import FriendlyNotFound from "./errors/FriendlyNotFound.tsx";
import "./index.css";
import RecoverPswIdentity from "./auth/RecoverPswIdentity.tsx";
import RecoverPsw from "./auth/RecoverPsw.tsx";
import PlansContapro from "./dashboard/PlansContapro.tsx";
import HomeDashboard from "./HomeDashboard.tsx";
import NewBusiness from "./business/NewBusiness.tsx";
import TagManager from "react-gtm-module";
import NewBusinessOnboardingForm from "./business/NewBusinessOnboardingForm.tsx";
import { Toaster } from "@/components/ui/toaster.tsx";
import PlansPricesContapro from "./dashboard/PlansPricesContapro.tsx";
import AllInProgressBusiness from "./dashboard/AllInProgressBusiness.tsx";
import PaymentWrapper from "./dashboard/payment/PaymentWrapper.tsx";
import LoginSiscont from "./siscont/LoginSiscont.tsx";
import RegisterAccountSiscont from "./siscont/RegisterAccountSiscont.tsx";
import RegisterBusinessSiscont from "./siscont/RegisterBusinessSiscont.tsx";

const gtmId = import.meta.env.VITE_GA4_ID;

function SimulatedError() {
  useEffect(() => {
    throw new Error("Simulated error");
  }, []);

  return <div>Simulated error</div>;
}

const routes = [
  {
    path: "/",
    element: <PublicRoutes />,
    errorElement: <ErrorPage />, // Fallback para cualquier error en estas rutas
    children: [
      { path: "/", element: <Login /> },
      { path: "/login", element: <Login /> },
      { path: "/crear-cuenta", element: <RegisterAccountant /> },
      { path: "/recuperar-contraseña-identificacion", element: <RecoverPswIdentity /> },
      { path: "/recuperar-contraseña/:token", element: <RecoverPsw /> },
      { path: "/simulated-error", element: <SimulatedError /> },
    ],
  },
  {
    path: "/",
    element: <PrivateRoutes />,
    errorElement: <ErrorPage />, // Fallback para cualquier error en estas rutas,
    children: [
      { id: "home", path: "/inicio/*", element: <Home /> },
      { path: "/registrar-empresa", element: <NewBusiness /> },
      {
        id: "dashboard",
        path: "/dashboard/*",
        element: <BusinessDashboardGuard />,
        children: [{ path: "*", element: <HomeDashboard /> }],
      },
      { id: "plans", path: "/planes", element: <PlansContapro /> },
      { id: "plans-and-prices", path: "/planes-y-precios", element: <PlansPricesContapro /> },
      { id: "registro-empresa", path: "/registro-empresa", element: <AllInProgressBusiness /> },
      { path: "/pago", element: <PaymentWrapper /> },
      { path: "/registrar-empresas", element: <NewBusinessOnboardingForm /> },
      { path: "/simulated-error", element: <SimulatedError /> },
    ],
  },
  { 
    path: "/siscont",
    children: [
      { path: "validate", element: <LoginSiscont /> },
      { path: "registrar-cuenta", element: <RegisterAccountSiscont /> },
      { path: "registrar-empresa", element: <RegisterBusinessSiscont /> },
    ] 
  },
  {
    path: "*",
    element: <FriendlyNotFound />,
  },
];

const router = createBrowserRouter(routes);

const App = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId });
  }, []);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
      <Toaster />
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
