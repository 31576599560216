import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import FormComponent from "@/business/FormComponent";
import { useBusinessStore } from "@/store/BusinessStore";
import { useDashboardStore } from "@/store/DashboardStore";
import { RegisterBusinessForm } from "@/business/helper";
import { EditCredentialsRequest } from "./models/EditCredentialsRequest";
import { useState } from "react";
import ModalValidationCredentials from "./components/ModalValidationCredentials";
import { StatusModal } from "./models/StatusValidation";
import BackButtonToBusiness from "@/components/shared/back_to_business_button";
import { useAuthStore } from "@/store/AuthStore";

const editForm = Yup.object().shape({
  name: Yup.string().required("Ingrese el nombre de la empresa"),
  ruc: Yup.string().required("Ingrese el RUC"),
  nombreSOL: Yup.string()
    .required("Ingrese el usuario SOL")
    .matches(/^[A-Z0-9]+$/, "El usuario SOL solo debe contener letras mayúsculas y números")
    .length(8, "El usuario SOL debe tener 8 caracteres"),
  llaveSOL: Yup.string().required("Ingrese su clave SOL"),
  sunat_api_key: Yup.string().optional(),
  sunat_api_secret: Yup.string().optional(),
});

function EditCredentials() {
  const navigate = useNavigate();
  const [openModalValidation, setOpenModalValidation] = useState(false);
  const [statusModalValidation, setStatusModalValidation] = useState<StatusModal>("close");
  const [messageModalValidation, setMessageModalValidation] = useState<string>("");
  const business = useBusinessStore((state) => state.business);
  const { editCredentials, validateCredentials } = useDashboardStore((state) => state);
  const accountant_profile = useAuthStore((state) => state.user.accountant_profile);
  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
    getValues,
    reset,
  } = useForm<RegisterBusinessForm>({
    resolver: yupResolver(editForm),
    mode: "onBlur",
    defaultValues: {
      name: business.name,
      ruc: business.ruc,
    },
  });

  const handleClose = () => {
    setOpenModalValidation(false);
    setStatusModalValidation("close");
    setMessageModalValidation("");
    if (
      ["update-credentials-success", "validate-credentials-success"].includes(statusModalValidation)
    ) {
      navigate("/inicio");
    }
  };

  const handleValidation = async () => {
    try {
      const dataForm = getValues();
      const request: EditCredentialsRequest = {
        ruc: business.ruc,
        username: dataForm.nombreSOL,
        password: dataForm.llaveSOL,
        sunat_api_key: dataForm.sunat_api_key,
        sunat_api_secret: dataForm.sunat_api_secret,
      };
      setStatusModalValidation("validate-credentials-initialize");
      const response = await validateCredentials(request);
      /**
       * PORTAL SUNAT
       * 200 - success
       * 400 - error en login (ERR-LIB-SOL-LOGIN-0004, ERR-LIB-SOL-LOGIN-0001)
       * 503 - otros tipos de errores (Intermitencia)
       *
       * API SUNAT (GENERACION DE TOKEN)
       * 400 - Error de credenciales
       */
      if (response.code === 200) {
        setStatusModalValidation("validate-credentials-success");
        reset();
      } else {
        /**
         * puede ser portal-sunat o api-sunat
         */
        setStatusModalValidation("validate-credentials-warning");
        const isPortalSunat = response.source_validation === "portal-sunat";
        const isApiSunat = response.source_validation === "api-sunat";
        if (isPortalSunat && response.code === 400) {
          setMessageModalValidation(
            "Validando tus credenciales contra el portal de SUNAT no hemos podido ingresar, verifica de nuevo tus credenciales y vuelve a intentar en unos minutos.",
          );
        } else if (isPortalSunat && response.code === 503) {
          setMessageModalValidation(
            "El portal de SUNAT está inestable en estos momentos y no podemos validar tus credenciales, puedes volver a realizar la actualizacion de credenciales en unos momentos o nosotros mismos con sus datos ya actualizados lo hacemos de manera automática.",
          );
        } else if (isPortalSunat) {
          setMessageModalValidation(
            "Estamos presentando problemas con nuestros servicios en estos momentos, vuelve a intentarlo luego de unos minutos",
          );
        } else if (isApiSunat && response.code === 400) {
          setMessageModalValidation(
            "Ya que has ingresado tanto el API KEY como el API SECRET estamos intentando validar tus credenciales mediante la API de SUNAT pero no obtenemos respuesta exitosa, verifica de nuevo tus credenciales y vuelve a intentar en unos minutos.",
          );
        } else if (isApiSunat) {
          setMessageModalValidation(
            "Ya que has ingresado tanto el API KEY como el API SECRET estamos intentando validar tus credenciales mediante la API de SUNAT pero por el momento la API de SUNAT se encuentra inestable y no podemos realizar la validación.",
          );
        }
      }
    } catch (error) {
      console.error("validated credentials error: ", error);
      setStatusModalValidation("validate-credentials-error");
    }
  };

  const onEditar = async (data: RegisterBusinessForm) => {
    try {
      const request: EditCredentialsRequest = {
        username: data.nombreSOL,
        password: data.llaveSOL,
        sunat_api_key: data.sunat_api_key,
        sunat_api_secret: data.sunat_api_secret,
      };
      await editCredentials(request);
      setOpenModalValidation(true);
      setStatusModalValidation("update-credentials-success");
    } catch (error) {
      console.error("update credentials error: ", error);
      setOpenModalValidation(true);
      setStatusModalValidation("update-credentials-error");
    }
  };
  return (
    <>
      <BackButtonToBusiness accountantProfile={accountant_profile} link="/inicio" />
      <br />
      <h2 className="text-xl font-semibold">Editar Credenciales</h2>
      <p className="text-sm mt-3">
        Sólo edita estas credenciales si hubo un error al ingresarlas. De lo contrario, no podremos
        extraer la información para la declaración.
      </p>
      <div className="w-2/4">
        <form onSubmit={handleSubmit(onEditar)} autoComplete="off">
          <FormComponent register={register} errors={errors} origin={"edit"} />
          <Button type="submit" className="mt-4" disabled={!isValid}>
            Editar credenciales
          </Button>
        </form>
      </div>
      <ModalValidationCredentials
        open={openModalValidation}
        statusModal={statusModalValidation}
        onClose={handleClose}
        onValidation={handleValidation}
        message={messageModalValidation}
      />
    </>
  );
}

export default EditCredentials;
