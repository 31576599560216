import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/solid'

interface BackButtonToListProps {
  accountantProfile: string | null;
  link: string;
}

const BackButtonToBusiness: React.FC<BackButtonToListProps> = ({
  accountantProfile,
  link,
}) => {
  if (accountantProfile !== 'Siscont') {
    return (
      <Link to={link} className="flex items-center gap-1">
        <ChevronLeftIcon className="w-4 h-4" />
        <span className="text-[#5D6075] text-sm f-secondary">Volver a listado de empresas</span>
      </Link>
    );
  }
  return null;
};

export default BackButtonToBusiness;