import { ModalState } from "@/shared/Modal";
import { useState } from "react";
import { useDashboardStore } from "@/store/DashboardStore";
import RejectedOneInvoiceModal from "@/dashboard/observeds/components/RejectedOneInvoiceModal";
import { Button } from "@/components/ui/button";

const ActionsPendingRowCell = ({
  cpe,
  currentPage,
  pageSize,
  setSelectedRows,
}: {
  cpe: Cpes;
  currentPage: number;
  pageSize: number;
  setSelectedRows: (rows: Cpes[]) => void;
}) => {
  const [isModalOpenDeclineReport, setModalOpenDeclineReport] = useState<ModalState>(
    ModalState.CLOSE,
  );

  const { fetchValidatedReport } = useDashboardStore((state) => state);

  const declineReport = () => {
    setModalOpenDeclineReport(ModalState.OPEN);
  };

  const handleOnClickCloseModalRejected = () => {
    if (isModalOpenDeclineReport === ModalState.DONE) {
      setSelectedRows([]);
      fetchValidatedReport(currentPage, pageSize);
    }
    setModalOpenDeclineReport(ModalState.CLOSE);
  };

  return (
    <div className="actions flex gap-3 max-w-fit">
      <Button variant={"destructive"} onClick={() => declineReport()}>
        Rechazar
      </Button>
      <RejectedOneInvoiceModal
        modalState={isModalOpenDeclineReport}
        setModalState={setModalOpenDeclineReport}
        closeModalCallback={() => handleOnClickCloseModalRejected()}
        cpes={cpe}
      />
    </div>
  );
};

export default ActionsPendingRowCell;
