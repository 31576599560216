import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogTitle } from "@/components/ui/dialog";
import { StatusModal } from "../models/StatusValidation";
import spinner from "/assets/spinner.svg?url";
import spc_mobile_success_hand_d_l from "/assets/spc_mobile_success_hand_d_l.svg?url";

interface ModalValidationCredentialsProps {
  statusModal: StatusModal;
  message: string;
  open: boolean;
  onClose(): void;
  onValidation(): void;
}

const getInformationForModal = (status: StatusModal) => {
  switch (status) {
    case "update-credentials-success":
      return {
        title: "Actualización de credenciales",
        description:
          "Ya hemos actualizado tus credenciales, ¿Deseas que realicemos la validación en el Portal SOL - SUNAT?",
      };

    case "update-credentials-error":
      return {
        title: "Actualización de credenciales",
        description:
          "Parece que hubo un problema y no hemos podido actualizar tus credenciales, vuelve a intentarlo en unos minutos.",
      };

    case "validate-credentials-initialize":
      return {
        title: "Validación de credenciales",
        description:
          "Estamos validando que tus credenciales sean correctas, este proceso tomará como máximo 1 minuto.",
      };

    case "validate-credentials-success":
      return {
        title: "Validación de credenciales",
        description:
          "Tus credenciales son correctas, estamos volviendo a extraer tu información de SUNAT. Podrás ver tus comprobantes en un plazo máximo de 24 horas.",
      };

    case "validate-credentials-error":
      return {
        title: "Validación de credenciales",
        description:
          "Sucedió un error inesperado a la hora de validar tus credenciales, vuelve a intentarlo en unos minutos",
      };

    default:
      return {
        title: "Validación de credenciales",
        description: "",
      };
  }
};

export default function ModalValidationCredentials({
  statusModal,
  message = "",
  open,
  onClose,
  onValidation,
}: ModalValidationCredentialsProps) {
  const { title, description } = getInformationForModal(statusModal);

  return (
    <Dialog open={open} modal={true}>
      <DialogContent className="py-8" aria-describedby={undefined} withButtonClose={false}>
        <DialogTitle className="text-neutral-darkest font-bold text-center text-[24px]">
          {title}
        </DialogTitle>

        {statusModal === "validate-credentials-initialize" && (
          <div className="p-1 flex justify-center">
            <img className="animate-spin h-8 w-8 text-blue-600" src={spinner} alt="spinner" />
          </div>
        )}
        {["validate-credentials-success", "update-credentials-success"].includes(statusModal) && (
          <div className="p-1 flex justify-center">
            <img src={spc_mobile_success_hand_d_l} alt="mobile_in_hand" />
          </div>
        )}
        <DialogDescription className="text-neutral-normal font-medium text-[16px]">
          {message || description}
        </DialogDescription>

        {statusModal === "update-credentials-success" && (
          <div className="flex justify-center gap-2">
            <Button type="button" onClick={onValidation} className="mt-2">
              Validar ahora
            </Button>
            <Button type="button" variant={"secondary"} onClick={onClose} className="mt-2">
              En otro momento
            </Button>
          </div>
        )}
        {[
          "update-credentials-error",
          "validate-credentials-error",
          "validate-credentials-success",
          "validate-credentials-warning",
        ].includes(statusModal) && (
          <Button type="button" onClick={onClose} className="mt-2">
            {statusModal === "validate-credentials-success" ? "Volver a mis empresas" : "Entendido"}
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
}
