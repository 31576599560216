import { ModalState } from "@/shared/Modal";
import { useState } from "react";
import ValidateOneInvoiceModal from "./ValidateOneInvoiceModal";
import { useDashboardStore } from "@/store/DashboardStore";
import RejectedOneInvoiceModal from "./RejectedOneInvoiceModal";

const ActionsPendingRowCell = ({
  cpe,
  currentPage,
  pageSize,
  setSelectedRows,
}: {
  cpe: Cpes;
  currentPage: number;
  pageSize: number;
  setSelectedRows: (rows: Cpes[]) => void;
}) => {
  const [isModalOpenValidateReport, setModalOpenValidateReport] = useState<ModalState>(
    ModalState.CLOSE,
  );
  const [isModalOpenDeclineReport, setModalOpenDeclineReport] = useState<ModalState>(
    ModalState.CLOSE,
  );

  const { fetchDashboardReportsObserved, fetchDashboardReportsRejected } = useDashboardStore(
    (state) => state,
  );
  const validateReport = () => {
    setModalOpenValidateReport(ModalState.OPEN);
  };

  const handleOnClickCloseModalValidate = () => {
    if (isModalOpenValidateReport === ModalState.DONE) {
      setSelectedRows([]);
      fetchDashboardReportsObserved(currentPage, pageSize);
    }
    setModalOpenValidateReport(ModalState.CLOSE);
  };

  const declineReport = () => {
    setModalOpenDeclineReport(ModalState.OPEN);
  };

  const handleOnClickCloseModalRejected = () => {
    if (isModalOpenDeclineReport === ModalState.DONE) {
      setSelectedRows([]);
      fetchDashboardReportsObserved(currentPage, pageSize);
      fetchDashboardReportsRejected(currentPage, pageSize);
    }
    setModalOpenDeclineReport(ModalState.CLOSE);
  };

  return (
    <div className="actions flex gap-3 max-w-fit">
      <button
        className="bg-success-light shrink-0 w-[76px] rounded-[20px] px-3 py-2 font-xs text-success-dark font-bold"
        onClick={() => validateReport()}
      >
        Validar
      </button>
      <ValidateOneInvoiceModal
        modalState={isModalOpenValidateReport}
        setModalState={setModalOpenValidateReport}
        closeModalCallback={() => handleOnClickCloseModalValidate()}
        cpes={cpe}
      />
      <button
        className="bg-danger-lightest shrink-0 rounded-[20px] px-3 py-2 font-bold font-xs text-error-dark"
        onClick={() => declineReport()}
      >
        Rechazar
      </button>
      <RejectedOneInvoiceModal
        modalState={isModalOpenDeclineReport}
        setModalState={setModalOpenDeclineReport}
        closeModalCallback={() => handleOnClickCloseModalRejected()}
        cpes={cpe}
      />
    </div>
  );
};

export default ActionsPendingRowCell;
