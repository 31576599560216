import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectLabel,
  SelectGroup,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";

interface StatusInvoiceSelectProps {
  className?: string;
  statusSelected: string;
  setStatusSelected: (value: string) => void;
}

const STATUS_INVOICES = [
  { id: "0", value: "Todos" },
  { id: "1", value: "Validado" },
  { id: "2", value: "En proceso" },
  { id: "3", value: "Observado" },
  { id: "4", value: "Rechazado" },
];

export default function StatusInvoiceSelect({
  statusSelected,
  setStatusSelected,
  className,
}: StatusInvoiceSelectProps) {
  const handleSelectChange = (value: string): void => {
    setStatusSelected(value);
  };

  return (
    <Select value={statusSelected} onValueChange={handleSelectChange}>
      <SelectTrigger className={cn("wt-fit", className)}>
        <SelectValue placeholder="Estado de comprobante" />
      </SelectTrigger>
      <SelectContent align="end">
        <SelectGroup>
          <SelectLabel> Estado de comprobante</SelectLabel>
          {STATUS_INVOICES.map((status) => (
            <SelectItem key={status.value} value={status.id}>
              {status.value}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}




/**
 * cambiar cuando sea aprobado 
 * import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";

interface StatusInvoiceButtonsProps {
  className?: string;
  statusSelected: string;
  setStatusSelected: (value: string) => void;
}

const STATUS_INVOICES = [
  { id: "0", value: "Todos" },
  { id: "1", value: "Validado" },
  { id: "2", value: "En proceso" },
  { id: "3", value: "Observado" },
  { id: "4", value: "Rechazado" },
];

export default function StatusInvoiceButtons({
  statusSelected,
  setStatusSelected,
  className,
}: StatusInvoiceButtonsProps) {
  return (
    <div className={cn("flex flex-wrap mx-3 my-8", className)}>
      {STATUS_INVOICES.map((status) => (
        <Button
          key={status.id}
          variant="outlineSecondary"
          onClick={() => setStatusSelected(status.id)}
          className={cn(
            "m-1 text-sm",
            statusSelected === status.id
              ? "bg-black text-white"
              : "bg-white text-gray-700 hover:bg-gray-200"
          )}
          type="button"
        >
          {status.value}
        </Button>
      ))}
    </div>
  );
}
 */