import { ModalState } from "@/shared/Modal";
import { useState } from "react";
import { useDashboardStore } from "@/store/DashboardStore";
import ValidateOneInvoiceModal from "@/dashboard/observeds/components/ValidateOneInvoiceModal";
import RejectedOneInvoiceModal from "@/dashboard/observeds/components/RejectedOneInvoiceModal";
import { Button } from "@/components/ui/button";

const ActionsPendingRowCell = ({
  cpe,
  currentPage,
  pageSize,
  setSelectedRows,
  status,
  statusCpe,
}: {
  cpe: Cpes;
  currentPage: number;
  pageSize: number;
  setSelectedRows: (rows: Cpes[]) => void;
  status: string;
  statusCpe: string;
}) => {
  const [isModalOpenValidateReport, setModalOpenValidateReport] = useState<ModalState>(
    ModalState.CLOSE,
  );
  const [isModalOpenDeclineReport, setModalOpenDeclineReport] = useState<ModalState>(
    ModalState.CLOSE,
  );

  const { fetchAllInvoiceReport } = useDashboardStore((state) => state);
  const validateReport = () => {
    setModalOpenValidateReport(ModalState.OPEN);
  };

  const handleOnClickCloseModalValidate = () => {
    if (isModalOpenValidateReport === ModalState.DONE) {
      setSelectedRows([]);
      fetchAllInvoiceReport(status, currentPage, pageSize);
    }
    setModalOpenValidateReport(ModalState.CLOSE);
  };

  const declineReport = () => {
    setModalOpenDeclineReport(ModalState.OPEN);
  };

  const handleOnClickCloseModalRejected = () => {
    if (isModalOpenDeclineReport === ModalState.DONE) {
      setSelectedRows([]);
      fetchAllInvoiceReport(status, currentPage, pageSize);
    }
    setModalOpenDeclineReport(ModalState.CLOSE);
  };

  return (
    <div className="actions flex gap-3 max-w-fit">
      <Button
        variant={"success"}
        disabled={statusCpe === "gasto deducible"}
        onClick={() => validateReport()}
      >
        Validar
      </Button>

      <ValidateOneInvoiceModal
        modalState={isModalOpenValidateReport}
        setModalState={setModalOpenValidateReport}
        closeModalCallback={() => handleOnClickCloseModalValidate()}
        cpes={cpe}
      />

      <Button
        variant={"destructive"}
        disabled={statusCpe === "gasto no deducible"}
        onClick={() => declineReport()}
      >
        Rechazar
      </Button>
      <RejectedOneInvoiceModal
        modalState={isModalOpenDeclineReport}
        setModalState={setModalOpenDeclineReport}
        closeModalCallback={() => handleOnClickCloseModalRejected()}
        cpes={cpe}
      />
    </div>
  );
};

export default ActionsPendingRowCell;
