import FilterPeriodSelect from "@/components/shared/period-filter-select";
import { Button } from "@/components/ui/button";
import { Download } from "lucide-react";
import StatusInvoiceSelect from "./components/StatusInvoice";
import PaginationSizeSelect from "@/components/shared/pagination-size-select";
import { useEffect, useState } from "react";
import { FormatType } from "../validated-invoices/hooks/useDownload";
import { useDashboardStore } from "@/store/DashboardStore";
import usePagination from "@/hooks/use-pagination";
import useTableColumns from "./hooks/useTableColumns";
import FullTable from "@/components/ui/full-table";
import icDocumentCheckDenied from "/assets/ic_document_check_denied.svg?url";
import Pagination from "@/components/ui/pagination/pagination";
import useDownload from "./hooks/useDownload";
import { DownloadAlertModal } from "../validated-invoices/components/modals";
import { ModalState } from "@/shared/Modal";
import ValidateAllSelectInvoiceModal from "../observeds/components/ValidatedAllSelectInvoiceModal";
import RejectedAllSelectInvoiceModal from "../observeds/components/RejectedAllSelectInvoiceModal";
import useCountingSelectedInvoices from "./hooks/useCountingSelectedInvoices";
import { useAuthStore } from "@/store/AuthStore";
import { FilterInvoiceReport } from "../models/report.model";
import FilterDropdown from "./components/FilterDropdown";

function AllInvoices() {
  const [currentFilterBy, setCurrentFilterBy] = useState<string | undefined>(undefined);
  const [currentFilterValue, setCurrentFilterValue] = useState<string | undefined>(undefined); 
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [validatedInvoicesSelected, setValidatedInvoicesSelected] = useState<Cpes[]>([]);
  const [rejectedInvoicesSelected, setRejectedInvoicesSelected] = useState<
    { code: string; reason: string }[]
  >([]);
  const [isModalOpenMasiveValidated, setModalOpenMasiveValidated] = useState<ModalState>(
    ModalState.CLOSE,
  );
  const [isModalOpenMasiveRejected, setModalOpenMasiveRejected] = useState<ModalState>(
    ModalState.CLOSE,
  );
  const [isOpenDownloadOptions, setIsOpenDownloadOptions] = useState(false);
  const [statusSelected, setStatusSelected] = useState("0");
  const {
    valuesFilterSelected: { tax_period },
    fetchAllInvoiceReport,
    fetchAllInvoiceFilterReport,
    allInvoicesReport: report,
    allFilterInvoices: reportFilter,
  } = useDashboardStore((state) => state);

  const { currentPage, pageSize, onChangePage, onChangePageSize } = usePagination();
  const { columns, selectedRows, setSelectedRows } = useTableColumns(
    report?.data ?? [],
    currentPage,
    pageSize,
    statusSelected,
  );

  const filtersData: FilterInvoiceReport = {
    types_of_vouchers: reportFilter.types_of_vouchers,
    series_of_vouchers: reportFilter.series_of_vouchers,
    status_of_vouchers: reportFilter.status_of_vouchers,
    provider_of_vouchers: reportFilter.provider_of_vouchers,
    serie_number_of_vouchers: reportFilter.serie_number_of_vouchers,
  };

  const { status, alertOpen, handleDownload, clearAlert } = useDownload({
    page: currentPage,
    size: pageSize,
    invoicesCodeSelected: selectedRows,
    allInvoices: report?.data ?? [],
    statusDiscrimination: statusSelected,
  });

  const { notFilesToDownload, allCanBeValidated, allCanBeRejected, totalFiles } =
    useCountingSelectedInvoices({
      selectedInvoicesCodes: selectedRows,
      allInvoices: report?.data ?? [],
    });

  const toggleDropdownDownloadOptions = () => {
    setIsOpenDownloadOptions((prev) => !prev);
  };

  const handleDownloadSelected = (type: FormatType) => {
    handleDownload(type);
    setIsOpenDownloadOptions(false);
  };

  const handleClickCloseModalOpenMasiveValidated = () => {
    if (isModalOpenMasiveValidated === ModalState.DONE) {
      setSelectedRows([]);
      fetchAllInvoiceReport(statusSelected, currentPage, pageSize);
      fetchAllInvoiceFilterReport()
    }
    setModalOpenMasiveValidated(ModalState.CLOSE);
  };

  const handleClickCloseModalOpenMasiveRejected = () => {
    if (isModalOpenMasiveRejected === ModalState.DONE) {
      setSelectedRows([]);
      fetchAllInvoiceReport(statusSelected, currentPage, pageSize);
      fetchAllInvoiceFilterReport()
    }
    setModalOpenMasiveRejected(ModalState.CLOSE);
  };

  const validateAllSelectInvoices = () => {
    const filteredVouchers = allCanBeValidated
      .filter((voucher: { code: string }) => selectedRows.includes(voucher.code))
      .map((voucher) => ({
        code: voucher.code,
        checked: false,
        issue_date: voucher.issue_date,
        type_code: "",
        serie: voucher.serie,
        number: voucher.number,
        issuer_business_name: "",
        taxable_amount: 0,
        non_taxable_amount: 0,
        igv_amount: 0,
        observation: "",
        items: [],
        img_cpe_link: "",
      }));

    setValidatedInvoicesSelected(filteredVouchers);
    setModalOpenMasiveValidated(ModalState.OPEN);
  };

  const rejectedAllSelectInvoices = () => {
    const filteredVouchers = allCanBeRejected.map((voucher) => ({
      code: voucher.code,
      reason: "",
    }));
    setRejectedInvoicesSelected(filteredVouchers);
    setModalOpenMasiveRejected(ModalState.OPEN);
  };

  const { registerEventLog, setView } = useAuthStore((state) => state);

  useEffect(() => {
    setView("reporte_general_compras");
    const timer = setTimeout(() => {
      registerEventLog("view", "reporte_general_compras");
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const onSelectedFilter = (filter_by: string ,filter_value: string) => {
    setCurrentFilterBy(filter_by);
    setCurrentFilterValue(filter_value);
    setIsFilterActive(true); 
    fetchAllInvoiceReport(statusSelected, 1, pageSize, filter_by, filter_value);
    onChangePage(1);
  }

  const handleResetFilter = () => {
    setCurrentFilterBy(undefined);
    setCurrentFilterValue(undefined);
    setIsFilterActive(false); 
    fetchAllInvoiceReport(statusSelected, 1, pageSize, undefined, undefined); 
    onChangePage(1);
  };


  useEffect(() => {
    setSelectedRows([]);
    setIsOpenDownloadOptions(false);
    fetchAllInvoiceReport(
      statusSelected,
      currentPage,
      pageSize,
      currentFilterBy,
      currentFilterValue
    );
    fetchAllInvoiceFilterReport();
  }, [statusSelected, currentPage, pageSize, tax_period, currentFilterBy, currentFilterValue]);

  return (
    <div className="my-6">
      <div className="flex justify-between items-center">
        <h1 className="basis-1/4 font-semibold text-neutral-dark text-2xl mt-6 mb-8">
          Tus comprobantes
        </h1>
        <div className="basis-1/4 flex justify-end">
          <FilterPeriodSelect />
        </div>
      </div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex">
          <div className="basis-[20%]">
            <StatusInvoiceSelect
              statusSelected={statusSelected}
              setStatusSelected={setStatusSelected}
              className="w-full"
            />
          </div>
          <div className="basis-[15%] ml-4">
            <PaginationSizeSelect
              pageSize={pageSize}
              onChange={onChangePageSize}
              className="w-full"
            />
          </div>
          <div className="relative inline-block">
            <Button
              type="button"
              className="ml-4"
              variant="outlineSecondary"
              onClick={toggleDropdownDownloadOptions}
              loading={status === "downloading"}
              disabled={notFilesToDownload}
            >
              Descargar comprobantes <Download />
            </Button>

            {isOpenDownloadOptions && (
              <div className="absolute right-0 mt-1 w-56 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg z-10">
                <div className="py-1">
                  <button
                    onClick={() => {
                      handleDownloadSelected("xml");
                    }}
                    className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                  >
                    Formato XML ({totalFiles.xmlCount})
                  </button>
                  <button
                    onClick={() => {
                      handleDownloadSelected("jpeg");
                    }}
                    className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                  >
                    Formato JPEG ({totalFiles.imgCount})
                  </button>
                </div>
              </div>
            )}
          </div>
          <DownloadAlertModal open={alertOpen} status={status} onClose={clearAlert} />
        </div>

        <FilterDropdown
          columns={columns} 
          filters={filtersData}
          selectedFilter={onSelectedFilter}
          isFilterActive={isFilterActive} 
          onResetFilter={handleResetFilter}
        />
      </div>

      {(allCanBeValidated.length > 1 || allCanBeRejected.length > 1) && (
        <div className="flex gap-1 w-1/2 mb-4">
          {allCanBeValidated.length > 1 && (
            <>
              <Button variant={"success"} onClick={validateAllSelectInvoices}>
                Validar ({allCanBeValidated.length})
              </Button>
              <ValidateAllSelectInvoiceModal
                modalState={isModalOpenMasiveValidated}
                setModalState={setModalOpenMasiveValidated}
                closeModalCallback={() => handleClickCloseModalOpenMasiveValidated()}
                cpes={validatedInvoicesSelected}
              />
            </>
          )}
          {allCanBeRejected.length > 1 && (
            <>
              <Button variant={"destructive"} onClick={rejectedAllSelectInvoices}>
                Rechazar ({allCanBeRejected.length})
              </Button>
              <RejectedAllSelectInvoiceModal
                modalState={isModalOpenMasiveRejected}
                setModalState={setModalOpenMasiveRejected}
                closeModalCallback={() => handleClickCloseModalOpenMasiveRejected()}
                cpesSelected={rejectedInvoicesSelected}
              />
            </>
          )}
        </div>
      )}

      {report?.data && report?.data.length > 0 ? (
        <>
          <FullTable columns={columns} data={report?.data} />
          <Pagination
            currentPage={currentPage}
            pageCount={report?.metadata?.pagination.total_pages}
            onChangePage={onChangePage}
          />
        </>
      ) : (
        <div className="my-6 flex flex-col justify-center items-center">
          <img src={icDocumentCheckDenied} alt="no hay data" />
          <p className="mt-5 text-center font-bold">
            No hay comprobantes con los filtros seleccionados
          </p>
          <p className="text-sm mt-3 text-center font-normal">Seleccione otro periodo</p>
        </div>
      )}
    </div>
  );
}

export default AllInvoices;
