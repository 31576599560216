import { useEffect, useState } from "react";
import { SelectedInvoicesCountingProps } from "../models/download-all-invoices-report";
import { ReportAllInvoices } from "../models/all-invoices-report.model";

export default function useCountingSelectedInvoices({
  selectedInvoicesCodes,
  allInvoices,
}: SelectedInvoicesCountingProps) {
  const [notFilesToDownload, setNotFilesToDownload] = useState(false);
  const [allCanBeValidated, setAllCanBeValidated] = useState<ReportAllInvoices[]>([]);
  const [allCanBeRejected, setAllCanBeRejected] = useState<ReportAllInvoices[]>([]);
  const [totalFiles, setTotalFiles] = useState({ imgCount: 0, xmlCount: 0 });

  useEffect(() => {
    const notFiles = allInvoices.every(
      (invoice) => invoice.img_cpe_link === "" && invoice.xml_cpe_link === "",
    );

    const invoicesSelected = allInvoices.filter((invoice) =>
      selectedInvoicesCodes.includes(invoice.code),
    );

    const canBeValidated = invoicesSelected.filter(
      (invoice) => invoice.status !== "gasto deducible",
    );
    const canBeRejected = invoicesSelected.filter(
      (invoice) => invoice.status !== "gasto no deducible",
    );

    const imgCount =
      selectedInvoicesCodes.length === 0
        ? allInvoices.filter((invoice) => invoice.img_cpe_link !== "").length
        : invoicesSelected.filter((invoice) => invoice.img_cpe_link !== "").length;

    const xmlCount =
      selectedInvoicesCodes.length === 0
        ? allInvoices.filter((invoice) => invoice.xml_cpe_link !== "").length
        : invoicesSelected.filter((invoice) => invoice.xml_cpe_link !== "").length;

    setNotFilesToDownload(notFiles);
    setAllCanBeValidated(canBeValidated);
    setAllCanBeRejected(canBeRejected);
    setTotalFiles({ imgCount, xmlCount });
  }, [allInvoices, selectedInvoicesCodes]);

  return {
    notFilesToDownload,
    allCanBeValidated,
    allCanBeRejected,
    totalFiles,
  };
}
