import { Button } from "@/components/ui/button";
import { PreviewFileModal } from "./modals";
import { DownloadIcon, EyeIcon } from "lucide-react";
import { ButtonWithTooltip } from "./buttonTooltip";
import { ModalState } from "@/shared/Modal";
import { useState } from "react";
import { useAuthStore } from "@/store/AuthStore";

interface DescargarFacturaProps {
  url: string;
  type: "jpeg" | "xml";
}

export function VerFactura({ url }: { url: string }) {
  const [isModalOpenShowItems, setModalOpenShowItems] = useState<ModalState>(ModalState.CLOSE);
  const { registerEventLog, view } = useAuthStore((state) => state);

  const handleOnClickButton = () => {
    registerEventLog("preview", `${view}_ver_comprobante`);
    setModalOpenShowItems(ModalState.OPEN);
  };
  return url ? (
    <>
      <Button variant="ghost" size="sm" className="text-blue-normal" onClick={handleOnClickButton}>
        Ver comprobante <EyeIcon className="h-4 w-4" />
      </Button>

      <PreviewFileModal
        fileUrl={url}
        modalState={isModalOpenShowItems}
        closeModalCallback={() => setModalOpenShowItems(ModalState.CLOSE)}
      />
    </>
  ) : (
    <ButtonWithTooltip
      description="Estamos procesando la visualización del comprobante. Estará disponible en menos de 24 horas."
      side="bottom"
      className="max-w-60"
    >
      <Button variant="ghost" size="sm" className="text-neutral-light">
        Ver comprobante <EyeIcon className="h-4 w-4" />
      </Button>
    </ButtonWithTooltip>
  );
}

export function DescargarFactura({ url, type }: DescargarFacturaProps) {
  const format = type === "jpeg" ? `${type}` : type;
  return url ? (
    <Button variant="ghost" size="sm" className="text-[13px] text-blue-normal p-0" asChild>
      <a href={url}>
        {format} <DownloadIcon className="h-4 w-4" />
      </a>
    </Button>
  ) : (
    <ButtonWithTooltip
      description="Estamos procesando la descarga del comprobante. Estará disponible en menos de 24 horas."
      side="bottom"
      className="max-w-56"
    >
      <Button variant="ghost" size="sm" className="text-neutral-light text-[13px] p-0">
        {format} <DownloadIcon className="h-4 w-4" />
      </Button>
    </ButtonWithTooltip>
  );
}
