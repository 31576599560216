import React, { useEffect, useState } from "react";
import "./BusinessDashboard.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDashboardStore } from "../store/DashboardStore.tsx";
import Modal, { ModalState } from "../shared/Modal.tsx";
import Sales from "./Sales.tsx";
import Purchases from "./Purchase.tsx";
import axios from "axios";
import { saveAs } from "file-saver";
import { useAuthStore } from "../store/AuthStore.tsx";
import excelLightIcon from "/assets/ic_excel-light2.svg?url";
import { TabLayout, Tab, TabContent, TabContents, Tabs } from "../shared/TabLayout.tsx";
import { descendingComparator, formatNumber, getInitials } from "./helpers.ts";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalVideoTutorial from "./ModalVideoTutorial.tsx";
import { CONTAPRO_ANALYTICS } from "../shared/analytics.tsx";
import { eventGa4Service } from "../ga4.service.tsx";
import * as Yup from "yup";
import icMoneyPlusBlue from "/assets/ic_money_plus_blue.svg?url";
import BusinessErrorAlert from "./business-dashboard/components/BusinessErrorAlert";
import { useBusinessStore } from "@/store/BusinessStore.tsx";
import { getDateFormatted } from "@/helpers/dateFormatted.ts";
import BackButtonToBusiness from "@/components/shared/back_to_business_button.tsx";

interface CardOverviewProps {
  currencyCode: string;
  totalIgvSales: number;
  totalIgvPurchases: number;
  totalPositiveBalance: number;
  showPositiveBalance: boolean;
  totalIgv: number;
}

interface RadioCardProps {
  title: string;
  description: string;
  value: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface FiltersProps {
  filters: Filters;
  selectedBusiness: Business;
  selectedPeriod: string;
  setSelectedPeriod: (newTaxPeriod: string) => void;
  setModalOpenDownloadExcel: (newState: ModalState) => void;
}

interface DownloadExcelModalProps {
  modalState: ModalState;
  setModalState: (newState: ModalState) => void;
  closeModalCallback: () => void;
  selectedTaxPeriod: string | null;
  filters: Filters;
  selectedBusiness: Business;
}
interface SubModalShowInfoProps {
  onClose: () => void;
}

const validationSchema = Yup.object().shape({
  taxPeriod: Yup.string().required(),
  operationType: Yup.string().required(),
});

const CardOverviewIGVTotal = ({
  currencyCode,
  totalIgvSales,
  totalIgvPurchases,
  totalPositiveBalance,
  showPositiveBalance,
  totalIgv,
}: CardOverviewProps) => {
  const totalIgvAbsolut = Math.abs(totalIgv);

  return (
    <div>
      <div className="card-overview bg-white rounded-[12px] mt-4 lg:mt-6 p-4 xl:py-8 xl:px-32">
        <div className="block min-[380px]:flex items-center justify-between lg:max-w-[935px] mx-auto">
          <div className="card__overview__wrapper">
            <p className="text-sm text-[#5D6075] sm:text-2xl sm:text-[#15161C] font-medium f-secondary">
              {currencyCode} {formatNumber(totalIgvSales)}
            </p>
            <span className="text-xs card__overview__text--grey f-secondary sm:text-sm sm:text-[#15161C]">
              IGV de ventas
            </span>
          </div>

          <div className="card__overview__icon mx-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="4"
              viewBox="0 0 29 4"
              fill="none"
            >
              <path
                d="M1.875 3.875H26.625C27.0726 3.875 27.5018 3.69721 27.8182 3.38074C28.1347 3.06428 28.3125 2.63505 28.3125 2.1875C28.3125 1.73995 28.1347 1.31072 27.8182 0.994257C27.5018 0.677789 27.0726 0.5 26.625 0.5H1.875C1.42745 0.5 0.998225 0.677789 0.681757 0.994257C0.36529 1.31072 0.1875 1.73995 0.1875 2.1875C0.1875 2.63505 0.36529 3.06428 0.681757 3.38074C0.998225 3.69721 1.42745 3.875 1.875 3.875Z"
                fill="#1A4DDD"
              />
            </svg>
          </div>

          <div className="card__overview__wrapper">
            <p className="text-sm text-[#5D6075] sm:text-2xl sm:text-[#15161C] font-medium f-secondary">
              {currencyCode} {formatNumber(totalIgvPurchases)}
            </p>
            <span className="text-xs card__overview__text--grey f-secondary sm:text-sm sm:text-[#15161C]">
              IGV de compras <span className="font-bold">validadas</span>
            </span>
          </div>

          {showPositiveBalance && (
            <>
              <div className="card__overview__icon mx-auto hidden md:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="4"
                  viewBox="0 0 29 4"
                  fill="none"
                >
                  <path
                    d="M1.875 3.875H26.625C27.0726 3.875 27.5018 3.69721 27.8182 3.38074C28.1347 3.06428 28.3125 2.63505 28.3125 2.1875C28.3125 1.73995 28.1347 1.31072 27.8182 0.994257C27.5018 0.677789 27.0726 0.5 26.625 0.5H1.875C1.42745 0.5 0.998225 0.677789 0.681757 0.994257C0.36529 1.31072 0.1875 1.73995 0.1875 2.1875C0.1875 2.63505 0.36529 3.06428 0.681757 3.38074C0.998225 3.69721 1.42745 3.875 1.875 3.875Z"
                    fill="#1A4DDD"
                  />
                </svg>
              </div>
              <div className="card__overview__wrapper hidden md:block">
                <p className="text-sm text-[#5D6075] sm:text-2xl sm:text-[#15161C] font-medium f-secondary">
                  {currencyCode} {formatNumber(totalPositiveBalance)}
                </p>
                <span className="text-xs card__overview__text--grey f-secondary sm:text-sm sm:text-[#15161C]">
                  Saldo a favor del periodo anterior
                </span>
              </div>
            </>
          )}

          <div className="card__overview__icon mx-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="37"
              height="36"
              viewBox="0 0 37 36"
              fill="none"
            >
              <path
                d="M32.8125 22.5C32.8125 22.9476 32.6347 23.3768 32.3182 23.6932C32.0018 24.0097 31.5726 24.1875 31.125 24.1875H6.375C5.92745 24.1875 5.49822 24.0097 5.18176 23.6932C4.86529 23.3768 4.6875 22.9476 4.6875 22.5C4.6875 22.0524 4.86529 21.6232 5.18176 21.3068C5.49822 20.9903 5.92745 20.8125 6.375 20.8125H31.125C31.5726 20.8125 32.0018 20.9903 32.3182 21.3068C32.6347 21.6232 32.8125 22.0524 32.8125 22.5ZM6.375 15.1875H31.125C31.5726 15.1875 32.0018 15.0097 32.3182 14.6932C32.6347 14.3768 32.8125 13.9476 32.8125 13.5C32.8125 13.0524 32.6347 12.6232 32.3182 12.3068C32.0018 11.9903 31.5726 11.8125 31.125 11.8125H6.375C5.92745 11.8125 5.49822 11.9903 5.18176 12.3068C4.86529 12.6232 4.6875 13.0524 4.6875 13.5C4.6875 13.9476 4.86529 14.3768 5.18176 14.6932C5.49822 15.0097 5.92745 15.1875 6.375 15.1875Z"
                fill="#1A4DDD"
              />
            </svg>
          </div>

          <div className="card__overview__wrapper">
            <p className="text-base sm:text-2xl text-[#15161C] font-bold">
              {currencyCode} {formatNumber(totalIgvAbsolut)}
            </p>
            {totalIgv >= 0 ? (
              <span className="text-xs f-secondary sm:text-sm text-[#15161C] font-medium">
                IGV por pagar
              </span>
            ) : (
              <span className="text-xs f-secondary sm:text-sm text-[#15161C] font-medium">
                Saldo a Favor del periodo{" "}
              </span>
            )}
          </div>
        </div>
      </div>

      {showPositiveBalance && (
        <div className="flex gap-4 bg-[#E0E9FF] rounded-3xl px-5 py-4 md:hidden mt-4">
          <img src={icMoneyPlusBlue} alt="icon_bolsa" className="flex-none" />
          <div>
            <p className="font-semibold text-[#15161C] text-base f-secondary">
              {currencyCode} {formatNumber(Number(totalPositiveBalance))}
            </p>
            <span className="text-xs text-[#15161C] f-secondary">
              Saldo a favor del periodo anterior
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const RadioCard = ({ title, description, value, checked, onChange }: RadioCardProps) => {
  const handleCardClick = () => {
    if (!checked) {
      onChange({
        target: { value: value, checked: true, type: "radio" },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <div
      className={`flex-1 bg-[#E5F5FF] cursor-pointer px-2.5 py-4 rounded-xl ${
        checked ? "border-2 border-black" : ""
      } hover:shadow-lg`}
      onClick={handleCardClick}
    >
      <label className="block mb-2">
        <input
          type="radio"
          className="form-radio accent-black cursor-pointer transform scale-150"
          value={value}
          checked={checked}
          onChange={onChange}
        />
      </label>
      {value !== "siscont" && value !== "concar" && (
        <img src={`/assets/${value}.png`} alt={value} />
      )}
      <h2 className="text-lg font-medium my-2">{title}</h2>
      <div dangerouslySetInnerHTML={{ __html: description }}></div>
    </div>
  );
};

const Filters = ({
  filters,
  selectedBusiness,
  selectedPeriod,
  setSelectedPeriod,
  setModalOpenDownloadExcel,
}: FiltersProps) => {
  const valuesSelected = useDashboardStore((state) => state.valuesFilterSelected);
  const setValuesFilterSelected = useDashboardStore((state) => state.setValuesFilterSelected);
  const fetchDashboardStore = useDashboardStore((state) => state.fetchDashboardData);
  const fetchDashboardStoreHistory = useDashboardStore((state) => state.fetchDashboardDataHistory);
  const { registerEventLog } = useAuthStore((state) => state);
  const fetchDashboard = () => {
    Promise.all([fetchDashboardStore(), fetchDashboardStoreHistory()])
      .then(() => console.info("Dashboard data and history fetched"))
      .catch((error) => console.error("Error fetching dashboard data or history:", error));
  };

  const handleTaxPeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPeriod(e.target.value);

    setValuesFilterSelected({
      ...valuesSelected,
      tax_period: e.target.value,
    });

    fetchDashboard();
  };

  const handleOpenModalDownloadExcel = () => {
    eventGa4Service({
      action: CONTAPRO_ANALYTICS.DASHBOARD.DOWNLOAD_REPORT.ACTION,
    });
    registerEventLog("preview", "opciones_descarga");
    setModalOpenDownloadExcel(ModalState.OPEN);
  };

  return (
    <div>
      <div className="flex justify-between gap-3 items-center">
        <div className="flex items-center gap-3 md:hidden">
          <div className="w-10 h-10 p-4 rounded-[58px] bg-[#A7BFFC] flex justify-center items-center">
            <p className="font-medium text-sm f-secondary text-[#1840A5]">
              {getInitials(selectedBusiness.name)}
            </p>
          </div>
          <p className="font-semibold text-sm">
            <span className="block f-secondary text-sm font-semibold text-[#15161C] -mb-1">
              {selectedBusiness.name}
            </span>
            <span className="block f-secondary text-[10px] font-normal text-[#15161C]">
              RUC {selectedBusiness.ruc}
            </span>
          </p>
        </div>

        <div className="relative">
          <select
            onChange={handleTaxPeriodChange}
            value={selectedPeriod}
            id="period"
            className="business__select w-[100px] text-sm appearance-none rounded-[16px] cursor-pointer focus:border-1 focus:placeholder-gray-200 focus:bg-white focus:border-gray-600 focus:outline-none"
          >
            {filters.tax_periods?.sort(descendingComparator).map((period, i) => (
              <option value={period} key={`opt2-${i}`}>
                {period}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M10 12l-6-6-1.414 1.414L10 14.828l7.414-7.414L16 6z" />
            </svg>
          </div>
        </div>

        <div className="hidden md:block">
          <button
            id="download-report"
            className="btn__download bg-primary-black rounded-[40px] text-white text-center px-4 py-3"
            onClick={handleOpenModalDownloadExcel}
          >
            <span className="w-full flex align-middle justify-center">
              Descargar reporte{" "}
              <img className="text-white w-8 pl-2" src={excelLightIcon} alt="Excel Icon" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

const DownloadExcelModal = ({
  modalState,
  setModalState,
  closeModalCallback,
  selectedTaxPeriod,
  filters,
  selectedBusiness,
}: DownloadExcelModalProps) => {
  const [selectedOperationType, setSelectedOperationType] = useState("compras");
  const [isShowInfoSubModal, setIsShowInfoSubModal] = useState(false);
  const accessToken = useAuthStore((state) => state.accessToken);
  const setAccessTokenExpired = useAuthStore((state) => state.setAccessTokenExpired);
  const { registerEventLog } = useAuthStore((state) => state);

  const listOperationType = [
    {
      value: "compras",
      label: "Compras formato ContaPro",
      titleCard: "Compras Contapro",
      description: `<p class="text-sm">Archivo Excel. Incluye <span class="font-bold">ítems, imágenes y XML de comprobantes.</span></p>`,
    },
    {
      value: "ventas",
      label: "Ventas formato ContaPro",
      titleCard: "Ventas Contapro",
      description: `<p class="text-sm">Archivo Excel. Incluye <span class="font-bold">ítems e imágenes de comprobantes.</span></p>`,
    },
    {
      value: "comprasRce",
      label: "Compras RCE",
      titleCard: "Formato RCE",
      description: `<p class="text-sm">Archivo .txt para SIRE, incluye <span class="font-bold">solo los comprobantes deducibles. </span></p>`,
    },
    {
      value: "ventasRvie",
      label: "Ventas RVIE",
      titleCard: "Formato RVIE",
      description: `<p class="text-sm">Archivo .txt, <span class="font-bold">incluye todas las ventas del periodo. </span></p>`,
    },
    {
      value: "siscont",
      label: "Siscont",
      titleCard: "Formato SISCONT",
      description: `<p class="text-sm">Súbelo a tu sistema contable. Incluye<span class="font-bold"> solo los comprobantes deducibles. </span></p>`,
    },
    // {
    //     value: 'concar',
    //     label: 'Ventas RVIE',
    //     titleCard: 'Formato CONCAR',
    //     description: `<p class="text-sm">Súbelo a tu sistema contable. Incluye <span class="font-bold">solo los comprobantes deducibles. </span></p>`
    // },
  ];

  const openShowInfoSubModal = () => {
    setIsShowInfoSubModal(true);
  };

  const closeShowInfoSubModal = () => {
    setIsShowInfoSubModal(false);
  };

  const sendEventRegisterLog = (operationType: string) => {
    let eventDescription = "";
    switch (operationType) {
      case "compras":
        eventDescription = "contapro_compras";
        break;
      case "ventas":
        eventDescription = "contapro_ventas";
        break;
      case "comprasRce":
        eventDescription = "sire_propuesta_compras";
        break;
      case "ventasRvie":
        eventDescription = "sire_propuesta_ventas";
        break;
      case "siscont":
        eventDescription = "siscont_compras";
        break;
      default:
        eventDescription = "";
    }

    registerEventLog("download", eventDescription);
  };

  const { register, getValues, setValue } = useForm<{
    taxPeriod: string;
    operationType: string;
  }>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      taxPeriod: selectedTaxPeriod ?? "",
      operationType: "compras",
    },
  });

  useEffect(() => {
    setValue("taxPeriod", selectedTaxPeriod ?? "");
    setValue("operationType", "compras");
  }, [selectedBusiness, selectedTaxPeriod]);

  useEffect(() => {
    setValue("operationType", selectedOperationType);
  }, [selectedOperationType, setValue]);

  const setOperationType = (operationType: string) => {
    return operationType.includes("compra") ? "COMPRA" : "VENTA";
  };

  const setFileExtension = (operationType: string) => {
    const rce = operationType.toLowerCase().includes("rce");
    const rvie = operationType.toLowerCase().includes("rvie");
    return rce || rvie ? "txt" : "xlsx";
  };

  const getTagAnalytics = (operationType: string) => {
    const tag = {
      ["comprasRce"]: CONTAPRO_ANALYTICS.DASHBOARD.MODAL_DOWNLOAD_REPORT.OPTION_RCE.ACTION,
      ["ventasRvie"]: CONTAPRO_ANALYTICS.DASHBOARD.MODAL_DOWNLOAD_REPORT.OPTION_RVIE.ACTION,
      ["compras"]:
        CONTAPRO_ANALYTICS.DASHBOARD.MODAL_DOWNLOAD_REPORT.OPTION_PURCHASES_CONTAPRO.ACTION,
      ["ventas"]: CONTAPRO_ANALYTICS.DASHBOARD.MODAL_DOWNLOAD_REPORT.OPTION_SALES_CONTAPRO.ACTION,
    };

    return tag[operationType as keyof typeof tag];
  };

  const fetchBusinessDataFileReport = async () => {
    const { taxPeriod, operationType } = getValues();
    setModalState(ModalState.LOADING);
    eventGa4Service({
      action: CONTAPRO_ANALYTICS.DASHBOARD.MODAL_DOWNLOAD_REPORT.BUTTON_DOWNLOAD.ACTION,
    });
    const urlReports = `/app/internal/businesses/${selectedBusiness.ruc}/reports`;
    const url = operationType === "siscont" ? `${urlReports}/siscont` : urlReports;
    await axios
      .get(url, {
        params: {
          tax_period: taxPeriod,
          ...(operationType !== "siscont" && { operation_type: setOperationType(operationType) }),
          ...(operationType !== "siscont" && { file_extension: setFileExtension(operationType) }),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "json",
      })
      .catch((error) => {
        console.error("Error Response:", error.response);
        if (axios.isAxiosError(error)) {
          switch (error.response?.status) {
            case 401:
              setModalState(ModalState.ERROR);
              setAccessTokenExpired(true);
              throw new Response(error.message, { status: 401, statusText: error.message });
            case 404:
              setModalState(ModalState.ERROR);
              throw new Response(error.message, { status: 404, statusText: error.message });
            default:
              setModalState(ModalState.ERROR);
              throw new Response(error.message, { status: 500, statusText: error.message });
          }
        } else {
          setModalState(ModalState.ERROR);
          throw new Response("Unexpected error occurred", { status: 500 });
        }
      })
      .then((response: any) => {
        const sas = response.data.sas;
        const filename = response.data.blob_filename;

        // Download from sas url without redirecting
        axios({
          url: sas,
          method: "GET",
          responseType: "blob",
        })
          .then(function (response) {
            const blob = new Blob([response.data], { type: "application/octet-stream" });
            if (operationType !== "siscont") {
              eventGa4Service({
                action: getTagAnalytics(operationType),
              });
            }
            saveAs(blob, filename);
            setModalState(ModalState.DONE);
            sendEventRegisterLog(operationType);
          })
          .catch(function (error) {
            console.log(error);
            setModalState(ModalState.ERROR);
          });
      })
      .finally(() => {
        console.log("Finally fetch business data report");
        setValue("taxPeriod", selectedTaxPeriod ?? "");
        setValue("operationType", "compras");
        setSelectedOperationType("compras");
      });
  };

  const handleModalClose = () => {
    setSelectedOperationType("compras");
    closeModalCallback();
  };

  return (
    <>
      <Modal
        modalState={modalState}
        typeModal="downloadExcel"
        showAlertIcon={false}
        onClose={() => handleModalClose()}
        modalWidth="w-9/12 lg:w-4/6 max-w-4xl transform scale-75"
      >
        <div className="w-full">
          <h2 className="text-2xl font-bold mb-4">Descarga tu reporte</h2>
          <div className="lg:flex gap-4">
            <div className="flex-none mt-4 md:mt-0 relative">
              <span className="text-lg">Selecciona el periodo</span>
              <div className="relative">
                <select
                  id="period"
                  {...register("taxPeriod")}
                  defaultValue={getValues("taxPeriod")}
                  className="business__select w-full text-md appearance-none rounded-[16px] cursor-pointer focus:border-1 focus:placeholder-gray-200 focus:bg-white focus:border-gray-600 focus:outline-none"
                >
                  {filters.tax_periods?.map((period, i) => (
                    <option value={period} key={`tax-period-${period}-${i}`}>
                      {period}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 12l-6-6-1.414 1.414L10 14.828l7.414-7.414L16 6z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="block lg:hidden mt-4 relative">
              <span>Selecciona la base</span>
              <div className="relative">
                <select
                  id="operationType"
                  {...register("operationType")}
                  defaultValue={getValues("operationType")}
                  className="business__select w-full text-sm appearance-none rounded-[16px] cursor-pointer focus:border-1 focus:placeholder-gray-200 focus:bg-white focus:border-gray-600 focus:outline-none"
                >
                  {listOperationType.map((item) => (
                    <option key={"type-op" + item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 12l-6-6-1.414 1.414L10 14.828l7.414-7.414L16 6z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block mt-4">
            <h2 className="font-medium text-lg mb-3">
              Selecciona el archivo que quieres descargar
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {listOperationType.map((item, i) => (
                <React.Fragment key={"radio-card" + i}>
                  <RadioCard
                    key={"radio-card" + i}
                    title={item.titleCard}
                    description={item.description}
                    value={item.value}
                    checked={selectedOperationType === item.value}
                    onChange={() => setSelectedOperationType(item.value)}
                  ></RadioCard>
                  {i === 3 && <p className="col-span-4 font-medium text-lg">Otros formatos</p>}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex text-blue-500">
              <img src="/assets/ic_export_blue.svg" alt="Icono de importación de archivos" />
              <a className="ml-1 cursor-pointer" onClick={openShowInfoSubModal}>
                ¿Cómo subir los archivos a sistemas contables?
              </a>
            </div>
            <div className="flex lg:justify-end pt-4">
              <button
                id="btn-download-report"
                disabled={selectedOperationType === ""}
                className="h-12 hidden lg:block bg-primary-black rounded-[20px] text-xl text-white text-center px-6 py-2 disabled:bg-gray-400"
                onClick={fetchBusinessDataFileReport}
              >
                Descargar Archivo
              </button>
              <button
                className="block lg:hidden bg-primary-black rounded-[20px] text-xl text-white text-center px-6 py-2"
                onClick={fetchBusinessDataFileReport}
              >
                Descargar Archivo
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {isShowInfoSubModal && <SubModalShowInfo onClose={closeShowInfoSubModal} />}
    </>
  );
};

const SubModalShowInfo: React.FC<SubModalShowInfoProps> = ({ onClose }) => {
  // const URL_BASE_VIDEO = import.meta.env.VITE_URL_BASE_ASSETS;
  // const URL_VIDEO_REGISTRO_CONTAPRO = `${URL_BASE_VIDEO}/video_registro_contapro.mp4`;

  return (
    <Modal
      modalState={ModalState.OPEN}
      typeModal="generic"
      showAlertIcon={false}
      onClose={onClose}
      modalWidth="w-2/3 max-w-2xl"
    >
      <div className="w-full p-4 mx-6">
        {/* <h2 className="text-2xl font-bold mb-4 text-center">¡Nuevo! Descarga tus compras y ventas y expórtalas a SISCONT</h2> */}
        {/* <p className="text-sm mb-4">Olvídate de digitar comprobantes individuales, aprende cómo importar tus comprobantes aquí.</p> */}
        <p className="text-md mb-4 text-center">
          Olvídate de digitar comprobantes individuales, revisa el módulo de{" "}
          <span className="font-bold"> Descarga de reporte </span>para descargar tus comprobantes en
          formato SISCONT.
        </p>

        {/* <div className='flex justify-center'>
                    <video 
                        src={URL_VIDEO_REGISTRO_CONTAPRO} 
                        controls 
                        className="w-[420px] h-64 rounded-[25px] object-cover m-2.5" 
                        autoPlay
                    >
                    </video>
                </div> */}
        <div className="flex justify-center items-end h-16">
          <button
            className="bg-primary-black rounded-[20px] text-white text-center px-6 py-2 w-52 h-10"
            onClick={onClose}
          >
            Listo
          </button>
        </div>
      </div>
    </Modal>
  );
};

// TODO: Refactor this file 😥
function BusinessDashboardApp({ onCloseOnboardingVideo }: { onCloseOnboardingVideo(): void }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { setView } = useAuthStore((state) => state);
  const { ruc, businessName } = location.state || {};
  const business = useBusinessStore((state) => state.business);
  const {
    filters,
    dashboardData,
    resetReportsObserved,
    resetReportsPerceptions,
    fetchDashboardData,
    dashboardDataHistory,
    fetchDashboardDataHistory,
  } = useDashboardStore((state) => state);
  const accountant_profile = useAuthStore((state) => state.user.accountant_profile);
  const { tax_period } = useDashboardStore((state) => state.valuesFilterSelected);
  const [selectedPeriod, setSelectedPeriod] = useState(tax_period);
  const [isShowModalOnboardingDashboard, setIsShowModalOnboardingDashboard] = useState(false);
  const [isModalOpenDownloadExcel, setModalOpenDownloadExcel] = useState<ModalState>(
    ModalState.CLOSE,
  );

  useEffect(() => {
    setView("dashboard");
  }, []);

  useEffect(() => {
    resetReportsObserved();
    resetReportsPerceptions();

    const completedOnboardingVideo = localStorage.getItem("completedOnboardingVideo");
    if (!business) {
      navigate("/inicio");
    }

    if (!completedOnboardingVideo) {
      setIsShowModalOnboardingDashboard(true);
    }

    fetchDashboardData()
      .then(() => console.info("Dashboard fetched"))
      .catch((_) => {
        console.error("Dashboard data couldn't fetch");
      });
    fetchDashboardDataHistory()
      .then(() => console.info("Dashboard fetched from history"))
      .catch((_: any) => {
        console.error("Dashboard data couldn't fetch");
      });
  }, [navigate, ruc, businessName]);

  const dateFormatted = getDateFormatted();

  return (
    <>
      <div className="flex-auto">
        <div>
          <BackButtonToBusiness
            accountantProfile={accountant_profile}
            link="/inicio"
          />
          <div className="md:flex items-center justify-between mt-4">
            <h2 className="font-semibold text-xl flex-none mb-3 md:mb-0">Reporte de empresa</h2>
            <Filters
              filters={filters}
              selectedBusiness={business}
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
              setModalOpenDownloadExcel={setModalOpenDownloadExcel}
            />
          </div>
          {business.status && business.status !== "VALID" && <BusinessErrorAlert />}
        </div>

        {dashboardData && (
          <div id="business_dashboard">
            <CardOverviewIGVTotal
              currencyCode={dashboardData.currency_code}
              totalIgvSales={Number(dashboardData.sum_igv_sales)}
              totalIgvPurchases={Number(dashboardData.sum_igv_purchase)}
              totalPositiveBalance={Number(dashboardData.sum_positive_balance)}
              showPositiveBalance={dashboardData.show_positive_balance}
              totalIgv={Number(dashboardData.sum_igv_total)}
            />

            <div className="block mt-4 md:hidden">
              <button
                id="step-two-mobile"
                className="btn__download bg-primary-black rounded-[40px] text-white text-center px-4 py-3 w-full"
                onClick={() => {
                  setModalOpenDownloadExcel(ModalState.OPEN);
                }}
              >
                <span className="w-full flex items-center justify-center text-sm">
                  Descargar reporte en excel
                  <img className="text-white w-8 pl-2" src={excelLightIcon} alt="Excel Icon" />
                </span>
              </button>
            </div>

            <TabLayout initialTab="compras">
              <Tabs>
                <Tab
                  name="compras"
                  tagAnalytics={CONTAPRO_ANALYTICS.DASHBOARD.TAB_PURCHASES.ACTION}
                >
                  Compras
                </Tab>
                <Tab name="ventas" tagAnalytics={CONTAPRO_ANALYTICS.DASHBOARD.TAB_SALES.ACTION}>
                  Ventas
                </Tab>
              </Tabs>

              <TabContents>
                <TabContent name="compras">
                  <Purchases
                    dashboardData={dashboardData}
                    purchase={dashboardData.purchase}
                    currencyCode={dashboardData.currency_code}
                    businessInfo={business}
                    setModalOpenDownloadExcel={setModalOpenDownloadExcel}
                    dashboardDataHistory={dashboardDataHistory}
                  />
                </TabContent>
                <TabContent name="ventas">
                  <Sales
                    sales={dashboardData.sales}
                    currencyCode={dashboardData.currency_code}
                    businessInfo={business}
                    dashboardDataHistory={dashboardDataHistory}
                  />
                </TabContent>
              </TabContents>
            </TabLayout>
          </div>
        )}
        <div className="m-12 text-center">
          <p className="text-xs">
            {dateFormatted}{" "}
            <a
              className="text-blue-500 underline cursor-pointer"
              href="https://citrine-doll-649.notion.site/Preguntas-frecuentes-19660e3f26674d5d9c42573a81a2804b"
              target="_blank"
              rel="noopener noreferrer"
            >
              ¿No ves algún comprobante?
            </a>
          </p>
        </div>
      </div>

      <DownloadExcelModal
        modalState={isModalOpenDownloadExcel}
        setModalState={setModalOpenDownloadExcel}
        closeModalCallback={() => setModalOpenDownloadExcel(ModalState.CLOSE)}
        selectedTaxPeriod={selectedPeriod}
        filters={filters}
        selectedBusiness={business}
      />

      {isShowModalOnboardingDashboard && (
        <ModalVideoTutorial
          modalType="onboarding"
          showModal={isShowModalOnboardingDashboard}
          onClose={onCloseOnboardingVideo}
        />
      )}
    </>
  );
}

export default BusinessDashboardApp;
