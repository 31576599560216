import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDashboardStore } from "../../store/DashboardStore.tsx";
import "./BillsObservedDetails.css";
import Tabs from "../components/tabs.tsx";
import usePagination from "@/hooks/use-pagination.ts";
import FilterPeriodSelect from "@/components/shared/period-filter-select.tsx";
import { useBusinessStore } from "@/store/BusinessStore.tsx";
import PendingObserved from "./containers/PendingObserved.tsx";
import RejectedObserved from "./containers/RejectedObserved.tsx";
import useTableRejectedColumns from "./hooks/useTableRejectedColumns.tsx";
import useTablePendingColumns from "./hooks/useTablePendingColumns.tsx";
import CardsHeader from "./components/CardsHeader.tsx";
import { useAuthStore } from "@/store/AuthStore.tsx";
import BackButtonToBusiness from "@/components/shared/back_to_business_button";

const tabs = [
  {
    label: "Pendientes",
    value: "pending",
  },
  {
    label: "Rechazados",
    value: "rejected",
  },
];

function BillsObservedDetails() {
  const navigate = useNavigate();
  const accountant_profile = useAuthStore((state) => state.user.accountant_profile);
  const business = useBusinessStore((state) => state.business);
  const {
    reportsObserved,
    reportsRejected,
    fetchDashboardReportsObserved,
    fetchDashboardReportsRejected,
    valuesFilterSelected: { tax_period },
  } = useDashboardStore((state) => state);

  const {
    currentPage: currentPagePending,
    pageSize: pageSizePending,
    onChangePage: onChangePagePending,
    onChangePageSize: onChangePageSizePending,
  } = usePagination();
  const {
    columns: columnsPending,
    selectedRows: selectedRowsPending,
    setSelectedRows: setSelectedRowsPending,
    handleSelectAllChange: handleSelectAllChangePending,
  } = useTablePendingColumns(reportsObserved?.cpes || [], currentPagePending, pageSizePending);
  const {
    currentPage: currentPageRejected,
    pageSize: pageSizeRejected,
    onChangePage: onChangePageRejected,
    onChangePageSize: onChangePageSizeRejected,
  } = usePagination();
  const {
    columns: columnsRejected,
    selectedRows: selectedRowsRejected,
    setSelectedRows: setSelectedRowsRejected,
    handleSelectAllChange: handleSelectAllChangeRejected,
  } = useTableRejectedColumns(reportsRejected?.cpes || [], currentPageRejected, pageSizeRejected);

  const [currentTab, setCurrentTab] = useState("pending");

  const { registerEventLog, setView } = useAuthStore((state) => state);

  useEffect(() => {
    setView("reporte_observados");
    const timer = setTimeout(() => {
      registerEventLog("view", "reporte_observados");
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!business || !tax_period) {
      navigate("/inicio");
    }
    setSelectedRowsPending([]);
    fetchDashboardReportsObserved(currentPagePending, pageSizePending);
  }, [tax_period, currentPagePending, pageSizePending]);

  useEffect(() => {
    if (!business || !tax_period) {
      navigate("/inicio");
    }
    setSelectedRowsRejected([]);
    fetchDashboardReportsRejected(currentPageRejected, pageSizeRejected);
  }, [tax_period, currentPageRejected, pageSizeRejected]);

  return (
    <div>
      <BackButtonToBusiness
        accountantProfile={accountant_profile}
        link="/inicio"
      />
      <div className="min-[360px]:flex items-center gap-3 justify-between mt-4 lg:mt-6">
        <h2 className="bill-obs-detail__title">Observaciones</h2>
        <FilterPeriodSelect />
      </div>

      {reportsObserved && reportsRejected && (
        <>
          <CardsHeader
            reportsObserved={reportsObserved}
            currentTab={currentTab}
            reportsRejected={reportsRejected!}
          />
          <Tabs selectedTab={currentTab} onClick={setCurrentTab} tab={tabs} />
          {currentTab == tabs[0].value ? (
            <PendingObserved
              reportsObserved={reportsObserved}
              pagination={{
                currentPage: currentPagePending,
                pageSize: pageSizePending,
                onChangePage: onChangePagePending,
                onChangePageSize: onChangePageSizePending,
              }}
              tableColumns={{
                columns: columnsPending,
                selectedRows: selectedRowsPending,
                setSelectedRows: setSelectedRowsPending,
                handleSelectAllChange: handleSelectAllChangePending,
              }}
            />
          ) : (
            <RejectedObserved
              reportsRejected={reportsRejected}
              pagination={{
                currentPage: currentPageRejected,
                pageSize: pageSizeRejected,
                onChangePage: onChangePageRejected,
                onChangePageSize: onChangePageSizeRejected,
              }}
              tableColumns={{
                columns: columnsRejected,
                selectedRows: selectedRowsRejected,
                setSelectedRows: setSelectedRowsRejected,
                handleSelectAllChange: handleSelectAllChangeRejected,
              }}
            />
          )}
        </>
      )}
    </div>
  );
}

export default BillsObservedDetails;
